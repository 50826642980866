import React from "react";
import styled from "styled-components";
import useWindowSize from "../../../hooks/useWindowSize";
import { LiquidCircle } from "../../../icons/components/LiquidCircle/LiquidCircle";
import { useContentContext } from "../../../providers/Content.provider";
import Dash from "../../common/Dash/Dash";
import { AboutTag } from "./About.styles";

const Worker = ({ design, name, profession, image }) => {
    return (
        <div className="gridElem" style={{ background: `url(${image})center center/cover no-repeat` }}>
            <div className="nameWrapper">
                <div className="flexWrapper">
                    <div className="name">
                        <p className="nameText">{`${name}`}</p>
                    </div>
                    {design}
                </div>
            </div>
            <p className="profile" data-aos="fade-left">
                {profession}
            </p>
        </div>
    );
};

const About = () => {
    const width = useWindowSize();
    const { getWorkers, getTextGroup } = useContentContext();
    const workers = getWorkers();
    const textGroup = getTextGroup("aboutus");

    let design;
    if (width <= 450) {
        design = <DesignSquaresMobile />;
    } else design = <DesignSquares />;
    return (
        <AboutTag>
            <div className="container">
                <Dash color={"#ffffff"} countBefore={6} countAfter={1} top={"0px"} />
                <div className="wrapper">
                    <div className="absolute" data-aos="fade-left">
                        {/*<p className="word">modern</p>
                        <p className="word">digital</p>
                        <p className="word">solutions</p>*/}
                        <LiquidCircle />
                    </div>
                    <div className="mainText">
                        <h2 className="title" data-aos="fade-down">
                            {textGroup.heading.value}
                        </h2>
                        <p className="description" data-aos="fade-down">
                            {textGroup.text.value}
                        </p>
                    </div>
                    <div className="gridWrapper" data-aos="fade-right">
                        {workers.map((worker) => (
                            <Worker
                                design={design}
                                image={worker.image}
                                name={worker.name}
                                profession={worker.profession}
                                key={worker.name}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </AboutTag>
    );
};

const DesignSquaresTag = styled.div`
    display: flex;
    flex: 0 0 auto;
    margin-left: 7px;
    height: 31px;
    width: 53px;
    flex-wrap: wrap;
    column-gap: 7px;
    row-gap: 5px;
    .blue {
        background-color: #1e4fcd;
        width: 13px;
        height: 13px;
    }
    .white {
        background-color: #ffffff;
        width: 13px;
        height: 13px;
    }
    .long {
        width: 33px;
    }
`;

const DesignSquares = () => {
    return (
        <DesignSquaresTag>
            <div className="blue"></div>
            <div className="white long"></div>
            <div className="white"></div>
            <div className="blue long"></div>
        </DesignSquaresTag>
    );
};

const DesignSquaresMobileTag = styled.div`
    display: flex;
    flex: 0 0 auto;
    margin-left: 3px;
    height: 32px;
    width: 19px;
    flex-wrap: wrap;
    column-gap: 3px;
    row-gap: 4px;
    .blue {
        background-color: #1e4fcd;
        width: 8px;
        height: 8px;
    }
    .white {
        background-color: #ffffff;
        width: 8px;
        height: 8px;
    }
    .long {
        height: 20px;
    }
`;

const DesignSquaresMobile = () => {
    return (
        <DesignSquaresMobileTag>
            <div className="white"></div>
            <div className="blue"></div>
            <div className="blue long"></div>
            <div className="white long"></div>
        </DesignSquaresMobileTag>
    );
};

export default About;
