import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Typed from "typed.js";
import MainWaveAnimation from "../../../animations/MainWaveAnimation/MainWaveAnimation";
import Button from "../../../elements/Button";
import { useContentContext } from "../../../providers/Content.provider";
import { scrollToElement } from "../../../utils/scrollToElement";
import Dash from "../../common/Dash/Dash";
import { MainTag } from "./Main.styles";

const Main = () => {
    const el = React.useRef(null);
    const typed = React.useRef(null);
    const [infinityAnim, setInfinityAnim] = useState(true);
    const { getText } = useContentContext();
    const text = (name) => getText("header", name).value || "";

    useEffect(() => {
        const options = {
            strings: [
                `<span style="color: #1E4FCD">[${text("heading_3.1")}]</span>`,
                `<span style="color: #1E4FCD">[${text("heading_3.2")}]</span>`,
                `<span style="color: #1E4FCD">[${text("heading_3.3")}]</span>`,
                `<span style="color: #1E4FCD">[${text("heading_3.4")}]</span>`,
            ],
            typeSpeed: 2,
            backSpeed: 20,
            onComplete: () => {
                setTimeout(() => {
                    setInfinityAnim(!infinityAnim);
                }, 3000);
            },
        };

        typed.current = new Typed(el.current, options);

        return () => {
            typed.current.destroy();
        };
    }, [infinityAnim]);

    return (
        <MainTag>
            <MainWaveAnimation />
            <div className="scroll" onClick={() => scrollToElement("main-second")}>
                <div className="scrollOutside"></div>
                <div className="scrollInner">
                    <div className="scrollCircle"></div>
                </div>
                <p className="scrollText">scroll</p>
            </div>
            <div className="container">
                <Dash color={"#000000"} countBefore={0} countAfter={7} bottom={"0px"} />
                <div className="wrapper">
                    <div className="left">
                        <h1 className="subtitle">{`${text("heading_1")}`}</h1>
                        <h2 className="title">
                            <span className="titleSpan">
                                {`${text("heading_2")}`} <br />
                                <span ref={el}></span>
                            </span>
                        </h2>
                        <div className="btnWrapper">
                            <Button
                                btnColor="#000000"
                                btnStyles={[
                                    {
                                        background: "#1ECD44",
                                        color: "#fff",
                                        border: "none",
                                    },
                                    { background: "#fff", color: "#1a1a1a", border: "1px solid #1a1a1a" },
                                ]}
                            />
                        </div>
                        <div className="stacks">
                            <div className="stack programLang" data-aos="fade-down">
                                <div className="stackItem">
                                    <div className="stackText">{`${text("tech_1")}`}</div>
                                </div>
                                <div className="stackItem stackItemActive">
                                    <div className="stackText bold">{`${text("tech_2")}`}</div>
                                </div>
                                <div className="stackItem">
                                    <div className="stackText">{`${text("tech_3")}`}</div>
                                </div>
                            </div>
                            <div className="stack frameworks" data-aos="fade-down" data-aos-delay="400">
                                <div className="stackItem stackItemActive">
                                    <div className="stackText bold">{`${text("tech_4")}`}</div>
                                </div>
                                <div className="stackItem">
                                    <div className="stackText">{`${text("tech_5")}`}</div>
                                </div>
                                <div className="stackItem">
                                    <div className="stackText">{`${text("tech_6")}`}</div>
                                </div>
                                <div className="stackItem">
                                    <div className="stackText">{`${text("tech_7")}`}</div>
                                </div>
                            </div>
                            <div className="stack cms" data-aos="fade-down" data-aos-delay="800">
                                <div className="stackItem">
                                    <div className="stackText">{`${text("tech_8")}`}</div>
                                </div>
                                <div className="stackItem stackItemActive">
                                    <div className="stackText bold">{`${text("tech_9")}`}</div>
                                </div>
                                <div className="stackItem">
                                    <div className="stackText">{`${text("tech_10")}`}</div>
                                </div>
                                <div className="stackItem">{`${text("tech_11")}`}</div>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="rightInner">
                            <h3 className="websites rightTitle">
                                <span
                                    className="rightTitleSpan"
                                    onClick={() => setTimeout(() => scrollToElement("#landings"), 1000)}
                                >
                                    {`${text("product_1")}`}
                                </span>
                            </h3>
                            <h3 className="applications rightTitle">
                                <span className="rightTitleSpan">
                                    <Link to="/apps">{`${text("product_2")}`}</Link>
                                </span>
                            </h3>
                            <h3 className="marketing rightTitle">
                                <span className="rightTitleSpan">
                                    <Link to="/branding">{`${text("product_3")}`}</Link>
                                </span>
                            </h3>
                            <h3 className="smm rightTitle">
                                <span className="rightTitleSpan">
                                    <Link to="/smm">{`${text("product_4")}`}</Link>
                                </span>
                            </h3>
                            <h3 className="webDevelopment rightTitle">
                                <span className="rightTitleSpan">
                                    <Link to="/systems">{`${text("product_5")}`}</Link>
                                </span>
                            </h3>
                            <h3 className="seo rightTitle">
                                <span className="rightTitleSpan">
                                    <Link to="/seo">{`${text("product_6")}`}</Link>
                                </span>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </MainTag>
    );
};

export default Main;
