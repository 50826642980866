import cn from "classnames";
import React from "react";
import { Element } from "react-scroll";
import arrows from "../../../images/contextPage/arrows.svg";
import arrows2 from "../../../images/contextPage/arrows2.svg";
import dollar from "../../../images/contextPage/dollar.svg";
import eye from "../../../images/contextPage/eye.svg";
import { useContentContext } from "../../../providers/Content.provider";
import styles from "./Includes.module.css";

const Includes = () => {
    const { getTextGroup } = useContentContext();

    const textGroup = getTextGroup("context_includes");

    return (
        <section className={styles.includes}>
            <Element name="context-second">
                <div className="container">
                    <div className={styles.wrapper}>
                        <h2 className={styles.title}>{textGroup.heading.value}</h2>
                        <p className={styles.descr}>{textGroup.subheading.value}</p>
                        <div className={styles.gridWrapper}>
                            <div className={cn(styles.gridItem, styles.white)}>
                                <h3 className={cn(styles.subtitle, styles.subtitleBlack)}>{textGroup.option_1_heading.value}</h3>
                                <p className={cn(styles.gridDescr, styles.gridDescrBlack)}>{textGroup.option_1_text.value}</p>
                                <img src={arrows} alt="arrows" className={styles.arrows} />
                            </div>
                            <div className={cn(styles.gridItem, styles.blue)}>
                                <h3 className={cn(styles.subtitle, styles.subtitleWhite)}>{textGroup.option_2_heading.value}</h3>
                                <p className={cn(styles.gridDescr, styles.gridDescrWhite)}>{textGroup.option_2_text.value}</p>
                                <img src={arrows2} alt="arrows" className={styles.arrows2} />
                            </div>
                            <div className={cn(styles.gridItem, styles.white)}>
                                <h3 className={cn(styles.subtitle, styles.subtitleBlack)}>{textGroup.option_3_heading.value}</h3>
                                <p className={cn(styles.gridDescr, styles.gridDescrBlack)}>{textGroup.option_3_text.value}</p>
                                <img src={dollar} alt="dollar" className={styles.dollar} />
                            </div>
                            <div className={cn(styles.gridItem, styles.blue)}>
                                <h3 className={cn(styles.subtitle, styles.subtitleWhite)}>{textGroup.option_4_heading.value}</h3>
                                <p className={cn(styles.gridDescr, styles.gridDescrWhite, styles.gridDescrLast)}>
                                    {textGroup.option_4_text.value}
                                </p>
                                <img src={eye} alt="eye" className={styles.eye} />
                            </div>
                        </div>
                    </div>
                </div>
            </Element>
        </section>
    );
};

export default Includes;
