import "aos/dist/aos.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import "./index.css";
import { AdminPage } from "./pages/AdminPage";
import { ContentProvider } from "./providers/Content.provider";
import { store } from "./store";
import ScrollToTop from "./utils/ScrollToTop";
// import Modal from "./components/Modal/Modal";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <ContentProvider>
            <BrowserRouter>
                <Routes>
                    <Route
                        path="*"
                        element={
                            <>
                                <ScrollToTop />
                                <App />
                            </>
                        }
                    />

                    <Route path="/admin" element={<AdminPage />} />
                </Routes>
            </BrowserRouter>
        </ContentProvider>
    </Provider>,
);
