import React from "react";
import { useContentContext } from "../../../providers/Content.provider";
import styles from "./Prices.module.css";

const Prices = () => {
    const { getTextGroup } = useContentContext();

    const textGroup = getTextGroup("apps_prices");
    return (
        <section className={styles.prices}>
            <div className="container">
                <h2 className={styles.title}>{textGroup.heading.value}</h2>
                <div className={styles.gridWrapper}>
                    <div className={styles.gridElement + " " + styles.black}>
                        <h3 className={styles.subtitle}>{textGroup.variant_1_title.value}</h3>
                        <div className={styles.price}>{textGroup.variant_1_price.value}</div>
                        <div className={styles.flexWrapper}>
                            <div className={styles.leftOptions}>
                                <p className={styles.option}>{textGroup.variant_1_option_1.value}</p>
                                <p className={styles.option}>{textGroup.variant_1_option_2.value}</p>
                                <p className={styles.option}>{textGroup.variant_1_option_3.value}</p>
                            </div>
                            <div className={styles.rightOptions}>
                                <p className={styles.option}>{textGroup.variant_1_option_4.value}</p>
                                <p className={styles.option}>{textGroup.variant_1_option_5.value}</p>
                                <p className={styles.option}>{textGroup.variant_1_option_6.value}</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.gridElement + " " + styles.blue}>
                        <h3 className={styles.subtitle}>{textGroup.variant_2_title.value}</h3>
                        <div className={styles.price}>{textGroup.variant_2_price.value}</div>
                        <div className={styles.flexWrapper}>
                            <div className={styles.leftOptions}>
                                <p className={styles.option}>{textGroup.variant_2_option_1.value}</p>
                                <p className={styles.option}>{textGroup.variant_2_option_2.value}</p>
                                <p className={styles.option}>{textGroup.variant_2_option_3.value}</p>
                            </div>
                            <div className={styles.rightOptions}>
                                <p className={styles.option}>{textGroup.variant_2_option_4.value}</p>
                                <p className={styles.option}>{textGroup.variant_2_option_5.value}</p>
                                <p className={styles.option}>{textGroup.variant_2_option_6.value}</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.gridElement + " " + styles.blue}>
                        <h3 className={styles.subtitle}>{textGroup.variant_3_title.value}</h3>
                        <div className={styles.price}>{textGroup.variant_3_price.value}</div>
                        <div className={styles.flexWrapper}>
                            <div className={styles.leftOptions}>
                                <p className={styles.option}>{textGroup.variant_3_option_1.value}</p>
                                <p className={styles.option}>{textGroup.variant_3_option_2.value}</p>
                                <p className={styles.option}>{textGroup.variant_3_option_3.value}</p>
                            </div>
                            <div className={styles.rightOptions}>
                                <p className={styles.option}>{textGroup.variant_3_option_4.value}</p>
                                <p className={styles.option}>{textGroup.variant_3_option_5.value}</p>
                                <p className={styles.option}>{textGroup.variant_3_option_6.value}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Prices;
