import cn from "classnames";
import React from "react";
import { Element } from "react-scroll";
import like from "../../../images/smmPage/like.svg";
import messages from "../../../images/smmPage/messages.svg";
import sell from "../../../images/smmPage/sell.svg";
import target from "../../../images/smmPage/target.svg";
import { useContentContext } from "../../../providers/Content.provider";
import styles from "./Includes.module.css";

const Includes = () => {
    const { getTextGroup } = useContentContext();

    const textGroup = getTextGroup("smm_includes");
    return (
        <section className={styles.includes}>
            <Element name="smm-second">
                <div className="container">
                    <div className={styles.wrapper}>
                        <h2 className={styles.title}>{textGroup.heading.value}</h2>
                        <p className={styles.descr}>{textGroup.subheading.value}</p>
                        <div className={styles.gridWrapper}>
                            <div className={cn(styles.gridItem, styles.white)}>
                                <h3 className={cn(styles.subtitle, styles.subtitleBlack)}>{textGroup.option_1_heading.value}</h3>
                                <p className={cn(styles.gridDescr, styles.gridDescrBlack)}>{textGroup.option_1_text.value}</p>
                                <img src={like} alt="like" className={styles.like} />
                            </div>
                            <div className={cn(styles.gridItem, styles.blue)}>
                                <h3 className={cn(styles.subtitle, styles.subtitleWhite)}>{textGroup.option_2_heading.value}</h3>
                                <p className={cn(styles.gridDescr, styles.gridDescrWhite)}>{textGroup.option_2_text.value}</p>
                                <img src={messages} alt="messages" className={styles.messages} />
                            </div>
                            <div className={cn(styles.gridItem, styles.white)}>
                                <h3 className={cn(styles.subtitle, styles.subtitleBlack)}>{textGroup.option_3_heading.value}</h3>
                                <p className={cn(styles.gridDescr, styles.gridDescrMiddle, styles.gridDescrBlack)}>
                                    {textGroup.option_3_text.value}
                                </p>
                                <img src={target} alt="target" className={styles.target} />
                            </div>
                            <div className={cn(styles.gridItem, styles.blue)}>
                                <h3 className={cn(styles.subtitle, styles.subtitleWhite)}>{textGroup.option_4_heading.value}</h3>
                                <p className={cn(styles.gridDescr, styles.gridDescrWhite, styles.gridDescrShort)}>
                                    {textGroup.option_4_text.value}
                                </p>
                                <img src={sell} alt="sell" className={styles.sell} />
                            </div>
                        </div>
                    </div>
                </div>
            </Element>
        </section>
    );
};

export default Includes;
