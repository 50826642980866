import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logotype from "../images/logotype.svg";
import { useContentContext } from "../providers/Content.provider";

const FooterTag = styled.footer`
    background-color: #1a1a1a;
    padding: 47px 0;

    .wrapper {
        display: flex;
    }

    .logoWrapper {
        margin-right: 97px;
    }

    .services {
        margin-top: 10px;
    }

    .services + .services {
        margin-left: 73px;
    }

    .contacts {
        margin-left: auto;
        margin-top: 10px;
    }

    .serviceLink {
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
        cursor: pointer;
    }

    .service + .service {
        margin-top: 20px;
    }

    .tel {
        text-align: right;
        margin-bottom: 21px;
    }

    .telLink {
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
    }

    .mail {
        font-size: 14px;
        line-height: 16px;
        text-align: right;
        color: #ffffff;
        margin-bottom: 21px;
    }

    .socials {
        display: flex;
        max-width: 141px;
        justify-content: space-between;
        margin-left: auto;
    }

    // ADAPTIVE

    @media (max-width: 900px) {
        padding: 50px 0;
        .logoWrapper {
            margin-right: 66px;
        }

        .services + .services {
            margin-left: 43px;
        }
    }
    @media (max-width: 767px) {
        .logoWrapper {
            margin-right: 46px;
        }

        .services + .services {
            margin-left: 33px;
        }
    }
    @media (max-width: 700px) {
        padding: 50px 0 38px;
        .wrapper {
            padding: 0 5px;
            flex-direction: column;
        }

        .logoWrapper {
            margin: 0 0 38px 0;
        }

        .services + .services {
            margin: 0;
        }

        .services {
            margin: 0;
        }

        .contacts {
            margin-left: unset;
            margin-top: 56px;
        }

        .tel,
        .mail,
        .socials {
            margin-left: unset;
            text-align: left;
        }

        .services__first {
            margin-bottom: 20px;
        }
    }
`;

const Footer = () => {
    const { getTextGroup } = useContentContext();

    const textGroup = getTextGroup("footer");

    const { getContacts } = useContentContext();

    const contacts = getContacts();

    return (
        <FooterTag>
            <div className="container">
                <div className="wrapper">
                    <div className="logoWrapper">
                        <img src={logotype} alt="logotype" />
                    </div>
                    <div className="services services__first">
                        <div className="service">
                            <Link to="/#landings" className="serviceLink">
                                Сайты
                            </Link>
                        </div>
                        <div className="service">
                            <Link to="/apps" className="serviceLink">
                                Приложения
                            </Link>
                        </div>
                        <div className="service">
                            <Link to="/systems" className="serviceLink">
                                Самописные системы
                            </Link>
                        </div>
                        <div className="service">
                            <Link to="/parser" className="serviceLink">
                                Парсинг
                            </Link>
                        </div>
                        <div className="service">
                            <Link to="/policy" className="serviceLink">
                                Политика
                                <br />
                                конфиденциальности
                            </Link>
                        </div>
                    </div>
                    <div className="services">
                        <div className="service">
                            <Link to="/branding" className="serviceLink">
                                Маркетинг
                            </Link>
                        </div>
                        <div className="service">
                            <Link to="/context" className="serviceLink">
                                Контекст
                            </Link>
                        </div>
                        <div className="service">
                            <Link to="/smm" className="serviceLink">
                                SMM
                            </Link>
                        </div>
                        <div className="service">
                            <Link to="/seo" className="serviceLink">
                                SEO
                            </Link>
                        </div>
                        <div className="service">
                            <Link to="/branding" className="serviceLink">
                                Брендинг и репутация
                            </Link>
                        </div>
                    </div>
                    <div className="contacts">
                        <script>
                            /*
                            <div className="tel">
                                <a href="tel:+79811036538" className="telLink">
                                    +7 981 103 65 38
                                </a>
                            </div>
                            */
                        </script>
                        <div className="mail">{textGroup.email.value}</div>
                        <div className="socials">
                            <div className="social">
                                <a href={contacts.find((contact) => contact.name === "telegram").value}>
                                    <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M20.6051 1.57763L17.4868 16.2837C17.2515 17.3216 16.638 17.5799 15.7661 17.091L11.0148 13.5897L8.72228 15.7948C8.46865 16.0484 8.25639 16.2607 7.76742 16.2607L8.10873 11.4216L16.9148 3.4643C17.2977 3.12298 16.8318 2.93382 16.3197 3.27518L5.43328 10.13L0.746532 8.66311C-0.272925 8.34479 -0.291343 7.6436 0.958698 7.15468L19.2905 0.0922834C20.1392 -0.225988 20.8819 0.28136 20.6051 1.57763Z"
                                            fill="#1E4FCD"
                                        />
                                    </svg>
                                </a>
                            </div>
                            <div className="social">
                                <a href={contacts.find((contact) => contact.name === "vk").value}>
                                    <svg width="25" height="14" viewBox="0 0 25 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M23.5403 0.610759H19.5643C19.402 0.610758 19.2428 0.654756 19.1035 0.738071C18.9642 0.821385 18.8502 0.940899 18.7734 1.08389C18.7734 1.08389 17.1876 4.00435 16.6783 4.98802C15.3079 7.6342 14.4206 6.80347 14.4206 5.57535V1.3408C14.4206 0.986944 14.28 0.647584 14.0298 0.397372C13.7796 0.14716 13.4402 0.00659233 13.0864 0.00659233H10.097C9.68984 -0.0236768 9.28175 0.0506329 8.91144 0.222464C8.54113 0.394295 8.2209 0.657942 7.98116 0.988363C7.98116 0.988363 9.49914 0.742927 9.49914 2.78827C9.49914 3.29546 9.52526 4.75451 9.54907 5.97827C9.55356 6.15861 9.5026 6.33598 9.40309 6.48644C9.30357 6.6369 9.16028 6.75319 8.99257 6.81963C8.82486 6.88607 8.6408 6.89946 8.46525 6.85797C8.28969 6.81648 8.13109 6.72213 8.01086 6.58765C6.78725 4.88581 5.77368 3.04241 4.9922 1.09748C4.92509 0.951984 4.81765 0.828796 4.68263 0.742526C4.54761 0.656257 4.39068 0.610526 4.23046 0.610759C3.36819 0.610759 1.64271 0.610759 0.61801 0.610759C0.518644 0.610083 0.4206 0.63354 0.332299 0.679115C0.243999 0.72469 0.168083 0.791021 0.111073 0.872409C0.0540633 0.953796 0.0176643 1.04781 0.00500325 1.14637C-0.00765785 1.24492 0.00379745 1.34508 0.038385 1.43824C1.1353 4.44706 5.86045 13.9024 11.2537 13.9024H13.5238C13.7616 13.9024 13.9897 13.8079 14.1579 13.6398C14.3261 13.4716 14.4206 13.2435 14.4206 13.0056V11.6349C14.4206 11.4629 14.4709 11.2947 14.5653 11.151C14.6597 11.0073 14.7941 10.8944 14.9519 10.8261C15.1098 10.7578 15.2841 10.7372 15.4535 10.7668C15.6229 10.7964 15.7799 10.8749 15.9052 10.9927L18.6223 13.5455C18.8663 13.7748 19.1885 13.9024 19.5234 13.9024H23.0913C24.8122 13.9024 24.8122 12.7083 23.874 11.7843C23.2137 11.134 20.831 8.62253 20.831 8.62253C20.6269 8.41115 20.5052 8.13375 20.4879 7.84047C20.4705 7.54719 20.5586 7.25737 20.7363 7.02338C21.5064 6.00988 22.7661 4.35085 23.3004 3.64013C24.0306 2.66871 25.3522 0.610759 23.5403 0.610759Z"
                                            fill="#1E4FCD"
                                        />
                                    </svg>
                                </a>
                            </div>
                            <div className="social">
                                <a href={contacts.find((contact) => contact.name === "whatsapp").value}>
                                    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M6.48478 4.3176C6.31027 3.92288 6.17794 3.90787 5.91356 3.89778C5.82356 3.89287 5.7233 3.88795 5.61206 3.88795C5.26809 3.88795 4.90852 3.98291 4.69153 4.19276C4.42716 4.44763 3.77114 5.04223 3.77114 6.26145C3.77114 7.4808 4.71263 8.66004 4.83961 8.82512C4.97194 8.98981 6.67519 11.5284 9.31992 12.563C11.3881 13.3725 12.0018 13.2975 12.4725 13.2026C13.1601 13.0628 14.0223 12.5828 14.2393 12.0033C14.4561 11.4236 14.4561 10.9289 14.3925 10.824C14.3291 10.719 14.1545 10.6593 13.8901 10.5342C13.6257 10.4092 12.3403 9.80953 12.097 9.72958C11.8589 9.64471 11.6315 9.67473 11.4518 9.91459C11.1978 10.2494 10.9493 10.5893 10.7482 10.7941C10.5896 10.954 10.3303 10.974 10.1135 10.889C9.82252 10.7743 9.00802 10.5041 8.00297 9.65972C7.22531 9.00522 6.69642 8.19081 6.54314 7.94591C6.38972 7.69609 6.52725 7.55105 6.64889 7.41625C6.78122 7.26126 6.90806 7.15142 7.04025 7.00639C7.17258 6.86149 7.24655 6.78659 7.3312 6.61659C7.4212 6.45177 7.35764 6.28177 7.29422 6.15679C7.23066 6.03155 6.70177 4.8122 6.48478 4.3176ZM8.99733 0C4.0358 0 0 3.81265 0 8.49987C0 10.3587 0.634781 12.0828 1.7138 13.4819L0.592312 16.6401L4.05169 15.5959C5.47453 16.4854 7.17244 17 9.00267 17C13.9642 17 18 13.1871 18 8.50013C18 3.81291 13.9642 0.000265625 9.00267 0.000265625H8.99747V0H8.99733Z"
                                            fill="#1E4FCD"
                                        />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </FooterTag>
    );
};

export default Footer;
