import cn from "classnames";
import React from "react";
import { Element } from "react-scroll";
import people from "../../../images/applicationsPage/people.svg";
import phone from "../../../images/applicationsPage/phone.svg";
import report from "../../../images/applicationsPage/report.svg";
import { useContentContext } from "../../../providers/Content.provider";
import styles from "./Includes.module.css";

const Includes = () => {
    const { getTextGroup } = useContentContext();

    const textGroup = getTextGroup("apps_includes");
    return (
        <section className={styles.includes}>
            <Element name="applications-second">
                <div className="container">
                    <div className={styles.wrapper}>
                        <h2 className={styles.title}>{textGroup.heading.value}</h2>
                        <p className={styles.descr}>{textGroup.subheading.value}</p>
                        <div className={styles.gridWrapper}>
                            <div className={cn(styles.gridItem, styles.white)}>
                                <h3 className={cn(styles.subtitle, styles.subtitleBlack)}>{textGroup.option_1_heading.value}</h3>
                                <p className={cn(styles.gridDescr, styles.gridDescrLong, styles.gridDescrBlack)}>
                                    {textGroup.option_1_text.value}
                                </p>
                                <img src={people} alt="people" className={styles.people} />
                            </div>
                            <div className={cn(styles.gridItem, styles.white)}>
                                <h3 className={cn(styles.subtitle, styles.subtitleBlack)}>{textGroup.option_2_heading.value}</h3>
                                <p className={cn(styles.gridDescr, styles.gridDescrShort, styles.gridDescrBlack)}>
                                    {textGroup.option_2_text.value}
                                </p>
                                <img src={report} alt="report" className={styles.report} />
                            </div>
                            <div className={cn(styles.gridItem, styles.blue)}>
                                <h3 className={cn(styles.subtitle, styles.subtitleWhite)}>{textGroup.option_3_heading.value}</h3>
                                <p className={cn(styles.gridDescr, styles.gridDescrWhite)}>{textGroup.option_3_text.value}</p>
                                <img src={phone} alt="phone" className={styles.phone} />
                            </div>
                        </div>
                    </div>
                </div>
            </Element>
        </section>
    );
};

export default Includes;
