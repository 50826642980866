import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import useWindowSize from "../../../hooks/useWindowSize";
import convertToSlide from "../../../utils/ConvertToSlide";
import { SliderTag } from "./Slider.styles";
import SwiperButtonNext from "./SwiperButtonNext";
import SwiperButtonPrev from "./SwiperButtonPrev";

const Slider = ({ typeOfCase, sliderData }) => {
    const dispatch = useDispatch();
    const width = useWindowSize();
    const [slidesPerView, setSlidesPerView] = useState(4);
    useEffect(() => {
        if (width >= 1100) {
            setSlidesPerView(4);
        }
        if (width < 1100) {
            setSlidesPerView(3);
        }
        if (width < 900) {
            setSlidesPerView(2);
        }
    }, [width]);
    const openModalCase = (activeCase) => {
        dispatch({
            type: "OPEN_CASE",
            payload: {
                activeCase,
                typeOfCase,
            },
        });
    };
    return (
        <SliderTag>
            <div className="sliderContainer">
                <Swiper
                    slidesPerView={slidesPerView}
                    spaceBetween={10}
                    slidesPerGroup={1}
                    loop={true}
                    loopFillGroupWithBlank={true}
                    className="mySwiper"
                >
                    <div className="navigateButtons">
                        <SwiperButtonPrev />
                        <SwiperButtonNext />
                    </div>
                    {width < 800
                        ? convertToSlide("mobile", sliderData).map((item, index) => {
                              return (
                                  <SwiperSlide key={index}>
                                      <div className="slideFlexWrapper">
                                          <div
                                              className="slideImgWrapper"
                                              onClick={() => openModalCase(sliderData[index * 3].ImageBig)}
                                          >
                                              <div
                                                  className="bgWrapper"
                                                  style={{
                                                      background: `url(${item.case1.img})center center/cover no-repeat`,
                                                  }}
                                              ></div>
                                              <div className="sliderInnerText">{item.case1.title}</div>
                                          </div>
                                          <div
                                              className="slideImgWrapper"
                                              onClick={() => openModalCase(sliderData[index * 3 + 1].ImageBig)}
                                          >
                                              <div
                                                  className="bgWrapper"
                                                  style={{
                                                      background: `url(${item.case2.img})center center/cover no-repeat`,
                                                  }}
                                              ></div>
                                              <div className="sliderInnerText">{item.case2.title}</div>
                                          </div>
                                          <div
                                              className="slideImgWrapper"
                                              onClick={() => {
                                                  const img = sliderData[index * 3 + 2].ImageBig;
                                                  if (img) openModalCase(sliderData[index * 3 + 2].ImageBig);
                                              }}
                                          >
                                              <div
                                                  className="bgWrapper"
                                                  style={{
                                                      background: `url(${item.case3.img})center center/cover no-repeat`,
                                                  }}
                                              ></div>
                                              <div className="sliderInnerText">{item.case3.title}</div>
                                          </div>
                                      </div>
                                  </SwiperSlide>
                              );
                          })
                        : convertToSlide("desktop", sliderData).map((item, index) => {
                              return (
                                  <SwiperSlide key={index}>
                                      <div className="slideFlexWrapper">
                                          <div
                                              className="slideImgWrapper"
                                              onClick={() => openModalCase(sliderData[index * 2].ImageBig)}
                                          >
                                              <div
                                                  className="bgWrapper"
                                                  style={{
                                                      background: `url(${item.case1.img})center center/cover no-repeat`,
                                                  }}
                                              ></div>
                                              <div className="sliderInnerText">{item.case1.title}</div>
                                          </div>
                                          <div
                                              className="slideImgWrapper"
                                              onClick={() => openModalCase(sliderData[index * 2 + 1].ImageBig)}
                                          >
                                              <div
                                                  className="bgWrapper"
                                                  style={{
                                                      background: `url(${item.case2.img})center center/cover no-repeat`,
                                                  }}
                                              ></div>
                                              <div className="sliderInnerText">{item.case2.title}</div>
                                          </div>
                                      </div>
                                  </SwiperSlide>
                              );
                          })}
                </Swiper>
            </div>
        </SliderTag>
    );
};

export default Slider;
