import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import closeWhite from "../../../images/modal/closeWhite.svg";
import nextArrow from "../../../images/modal/next.svg";
import prevArrow from "../../../images/modal/prev.svg";
import { ModalTag } from "./Modal.styles";

export const CaseModal = () => {
    const ref = useRef();
    const { isModal, activeCase, cases, typeOfCase } = useSelector((state) => state.caseModal);
    const dispatch = useDispatch();
    useEffect(() => {
        if (isModal) {
            document.querySelector("body").style.overflow = "hidden";
        } else document.querySelector("body").style.overflow = "visible";
    });
    useEffect(() => {
        if (isModal) {
            ref.current.scrollTop = 0;
        }
    }, [activeCase]);
    return (
        <>
            {isModal && (
                <ModalTag ref={ref} onClick={(e) => e.currentTarget === e.target && dispatch({ type: "CLOSE_CASE" })}>
                    <div className="contentWrapper">
                        <div className="content">
                            <img src={activeCase} alt="case" />
                        </div>
                    </div>
                    <button className="prev btn" onClick={() => dispatch({ type: "PREV_CASE" })}>
                        <img src={prevArrow} alt="prevCase" />
                    </button>
                    <button className="next btn" onClick={() => dispatch({ type: "NEXT_CASE" })}>
                        <img src={nextArrow} alt="nextCase" />
                    </button>
                    <button className="close" onClick={() => dispatch({ type: "CLOSE_CASE" })}>
                        <img src={closeWhite} alt="close" />
                    </button>
                </ModalTag>
            )}
        </>
    );
};

export default CaseModal;
