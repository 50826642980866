import React from "react";
import styled from "styled-components";
import SystemsAnimation from "../../../animations/SystemsAnimation/SystemsAnimation";
import Button from "../../../elements/Button";
import { useContentContext } from "../../../providers/Content.provider";
import { scrollToElement } from "../../../utils/scrollToElement";
import styles from "./Main.module.css";

const ButtonWrapperTag = styled.div`
    .btnWrapper {
        margin-top: 32px;
        display: flex;
        width: 353px;
        position: relative;
    }
    @media (max-width: 760px) {
        .btnWrapper {
            width: auto;
        }
    }
`;

const Main = () => {
    const { getTextGroup } = useContentContext();

    const textGroup = getTextGroup("system_header");
    return (
        <section className={styles.main}>
            <div className={styles.scroll} onClick={() => scrollToElement("systems-second")}>
                <div className={styles.scrollOutside}></div>
                <div className={styles.scrollInner}>
                    <div className={styles.scrollCircle}></div>
                </div>
                <p className={styles.scrollText}>scroll</p>
            </div>
            <div className="container">
                <div className={styles.wrapper}>
                    <div className={styles.left}>
                        <h1 className={styles.title}>{textGroup.heading_1.value}</h1>
                        <h2 className={styles.subtitle}>{textGroup.heading_2.value}</h2>
                        <p className={styles.descr}>{textGroup.heading_3.value}</p>
                        <ButtonWrapperTag>
                            <div className={"btnWrapper"}>
                                <Button
                                    btnColor="#000000"
                                    btnStyles={[
                                        { background: "#1ECD44", color: "#fff", border: "none" },
                                        { background: "#fff", color: "#1a1a1a", border: "1px solid #1a1a1a" },
                                    ]}
                                />
                            </div>
                        </ButtonWrapperTag>
                    </div>
                    <div className={styles.right}>
                        <SystemsAnimation />
                        {/*<img src={image} alt="animation" className={styles.image}/>*/}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Main;
