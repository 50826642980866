import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HOST } from "../constants";
import { useContentContext } from "../providers/Content.provider";

const headers = {
    "Content-Type": "application/json",
};

const getHeadersSecure = (token) => ({
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
});

const LoginScreen = ({ setToken }) => {
    const [form, setForm] = useState({ name: "", password: "" });

    const inputOnChange = (event) => setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));

    const submit = async () => {
        const res = await fetch(`${HOST}/auth/login`, {
            method: "POST",
            headers,
            body: JSON.stringify(form),
        });

        if (!res.ok) {
            return alert("Ошибка при входе");
        }
        const data = await res.json();

        localStorage.setItem("token", data.accessToken);

        setToken(data.accessToken);
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                maxWidth: "320px",
                width: "100%",
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                fontSize: "18px",
                backgroundColor: "#555",
                padding: "12px",
                borderRadius: "8px",
            }}
        >
            <span>{`Логин`}</span>
            <input
                value={form.name}
                onChange={inputOnChange}
                style={{ border: "1px solid black" }}
                type="text"
                name="name"
                placeholder="Введите логин..."
            />
            <span>{`Пароль`}</span>
            <input
                value={form.password}
                onChange={inputOnChange}
                style={{ border: "1px solid black" }}
                type="password"
                name="password"
                placeholder="Введите пароль..."
            />
            <button onClick={submit}>{`Войти`}</button>
        </div>
    );
};

const separator = (
    <div
        style={{
            width: "100%",
            padding: "4px 0",
            background: "white",
        }}
    ></div>
);

const Section = ({ children, onClick }) => (
    <button
        onClick={onClick}
        style={{
            background: "#1E4FCD",
            border: "1px solid black",
            borderRadius: "8px",
            color: "white",
            padding: "6px 0",
            fontSize: "18px",
        }}
    >
        {children}
    </button>
);

const SectionScreenTextItem = ({ text }) => {
    const initialForm = { group: text.group, name: text.name, value: text.value, id: text.id };
    const { fetchTexts } = useContentContext();
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [form, setForm] = useState(initialForm);
    const [pending, setPending] = useState(false);

    const sendChange = async () => {
        setPending(true);
        try {
            const res = await fetch(`${HOST}/texts`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(form),
            });
            if (!res.ok) {
                setPending(false);
                return alert("Произошла ошибка при запросе");
            }
            await fetchTexts();
            alert("Успешно!");
        } catch {
            alert("Произошла ошибка при запросе");
            setPending(false);
        }

        setPending(false);
    };
    return (
        <>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                    borderRadius: "8px",
                    border: "1px solid black",
                    background: "white",
                    padding: "12px",
                    color: "black",
                }}
            >
                <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                    <p style={{ fontWeight: "600" }}>
                        {text.label}
                        <span
                            style={{ fontSize: "12px", color: "#888", fontWeight: "400" }}
                        >{` ${text.group} ${text.name} ID:${text.id}`}</span>
                    </p>
                    <p>
                        <span style={{ fontSize: "18px" }}>{`${text.value}`}</span>
                    </p>
                </div>
                <button
                    onClick={() => {
                        if (!isFormOpen) {
                            setIsFormOpen(true);
                        } else {
                            setForm(initialForm);
                            setIsFormOpen(false);
                        }
                    }}
                    style={{ background: "#1E4FCD", color: "white", border: "1px solid black", borderRadius: "8px" }}
                >
                    {isFormOpen ? `Отмена` : `Изменить`}
                </button>
            </div>
            {isFormOpen ? (
                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    <textarea
                        onChange={(e) => {
                            setForm((prev) => ({ ...prev, value: e.target.value }));
                        }}
                        value={form.value}
                        name={"value"}
                        style={{ width: "100%", padding: "12px", height: "120px" }}
                    >
                        {}
                    </textarea>
                    <button
                        style={{
                            background: pending ? "gray" : "rgb(30,205,68)",
                            color: "white",
                            border: "none",
                            cursor: pending ? "not-allowed" : "pointer",
                        }}
                        onClick={sendChange}
                        disabled={pending}
                    >{`Изменить`}</button>
                </div>
            ) : null}
        </>
    );
};
const SectionScreenCaseItem = ({ caseItem }) => {
    const initialForm = {
        group: caseItem.group,
        value: caseItem.value,
        id: caseItem.id,
    };
    const { fetchCases } = useContentContext();
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [form, setForm] = useState(initialForm);
    const [pending, setPending] = useState(false);

    const sendChange = async (event) => {
        event.preventDefault();
        setPending(true);

        const formData = new FormData();
        formData.append("value", form.value);
        formData.append("id", form.id);
        formData.append("ImageBig", form.ImageBig);
        formData.append("ImageSmall", form.ImageSmall);

        try {
            const res = await fetch(`${HOST}/cases`, {
                method: "PATCH",

                body: formData,
            });
            if (!res.ok) {
                setPending(false);
                return alert("Произошла ошибка при запросе");
            }
            await fetchCases();
            alert("Успешно!");
        } catch {
            alert("Произошла ошибка при запросе");
            setPending(false);
        }

        setPending(false);
    };
    const deleteCase = async () => {
        setPending(true);

        // eslint-disable-next-line no-restricted-globals
        const isConfirm = confirm("Подтвердите удаление");
        if (!isConfirm) return;

        try {
            const res = await fetch(`${HOST}/cases`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ id: caseItem.id }),
            });
            if (!res.ok) {
                setPending(false);
                return alert("Произошла ошибка при запросе");
            }
            await fetchCases();
            alert("Успешно!");
        } catch {
            alert("Произошла ошибка при запросе");
            setPending(false);
        }

        setPending(false);
    };
    return (
        <>
            <div
                style={{
                    display: "flex",
                    alignItems: "flex-start",
                    width: "100%",
                    justifyContent: "space-between",
                    borderRadius: "8px",
                    border: "1px solid black",
                    background: "white",
                    padding: "12px",
                    color: "black",
                }}
            >
                <div style={{ display: "flex", flexDirection: "column", gap: "8px", width: "100%" }}>
                    <p
                        style={{
                            fontWeight: "600",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <span style={{ fontSize: "12px", color: "#888", fontWeight: "400" }}>{` ${caseItem.group} ID:${caseItem.id}`}</span>
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            <button
                                onClick={deleteCase}
                                style={{
                                    background: "red",
                                    color: "white",
                                    border: "1px solid black",
                                    borderRadius: "8px",
                                }}
                            >
                                {`Удалить`}
                            </button>
                            <button
                                onClick={() => {
                                    if (!isFormOpen) {
                                        setIsFormOpen(true);
                                    } else {
                                        setForm(initialForm);
                                        setIsFormOpen(false);
                                    }
                                }}
                                style={{
                                    background: "#1E4FCD",
                                    color: "white",
                                    border: "1px solid black",
                                    borderRadius: "8px",
                                }}
                            >
                                {isFormOpen ? `Отмена` : `Изменить`}
                            </button>
                        </div>
                    </p>
                    <p>
                        <span style={{ fontSize: "18px" }}>{`${caseItem.value}`}</span>
                    </p>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "flex-start",
                            maxHeight: "320px",
                            overflow: "hidden",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <img
                            style={{
                                width: "300px",
                                height: "320px",
                                objectFit: "cover",
                                objectPosition: "top",
                                border: "1px solid black",
                            }}
                            src={caseItem.ImageBig}
                            alt=""
                        />
                        <img style={{ width: "300px", border: "1px solid black" }} src={caseItem.ImageSmall} alt="" />
                    </div>
                </div>
            </div>
            {isFormOpen ? (
                <form
                    style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "column",
                        background: "#333",
                        padding: "8px",
                    }}
                >
                    <textarea
                        onChange={(e) => {
                            setForm((prev) => ({ ...prev, value: e.target.value }));
                        }}
                        value={form.value}
                        name={"value"}
                        style={{ width: "100%", padding: "12px", height: "120px" }}
                    >
                        {}
                    </textarea>
                    <label for="ImageBig">{`Файл большого скриншота`}</label>
                    <input
                        type="file"
                        id="ImageBig"
                        name="ImageBig"
                        onChange={(event) => {
                            setForm((prev) => ({ ...prev, ImageBig: event.target.files[0] }));
                        }}
                        style={{ marginBottom: "18px" }}
                    />
                    <label for="ImageSmall">{`Файл Миниатюры`}</label>
                    <input
                        type="file"
                        id="ImageSmall"
                        name="ImageSmall"
                        onChange={(event) => {
                            setForm((prev) => ({ ...prev, ImageSmall: event.target.files[0] }));
                        }}
                        style={{ marginBottom: "18px" }}
                    />
                    <button
                        style={{
                            background: pending ? "gray" : "rgb(30,205,68)",
                            color: "white",
                            border: "none",
                            cursor: pending ? "not-allowed" : "pointer",
                        }}
                        onClick={sendChange}
                        disabled={pending}
                    >{`Изменить`}</button>
                </form>
            ) : null}
        </>
    );
};
const SectionScreenCaseCreate = ({ group }) => {
    const initialForm = {
        group: group,
        value: "",
    };
    const { fetchCases } = useContentContext();
    const [form, setForm] = useState(initialForm);
    const [pending, setPending] = useState(false);

    const sendCreate = async (event) => {
        event.preventDefault();
        setPending(true);

        if (form.value.trim().length === 0 || !!!form.ImageBig || !!!form.ImageSmall) {
            return alert("Все поля должны быть заполнены");
        }

        const formData = new FormData();
        formData.append("value", form.value);
        formData.append("group", form.group);
        formData.append("ImageBig", form.ImageBig);
        formData.append("ImageSmall", form.ImageSmall);

        try {
            const res = await fetch(`${HOST}/cases`, {
                method: "POST",

                body: formData,
            });
            if (!res.ok) {
                setPending(false);
                return alert("Произошла ошибка при запросе");
            }
            await fetchCases();
            alert("Успешно!");
        } catch {
            alert("Произошла ошибка при запросе");
            setPending(false);
        }

        setPending(false);
    };
    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
            <h2>{`Добавить Кейс`}</h2>
            <form
                style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    background: "#333",
                    padding: "8px",
                }}
            >
                <textarea
                    onChange={(e) => {
                        setForm((prev) => ({ ...prev, value: e.target.value }));
                    }}
                    placeholder="Описание"
                    value={form.value}
                    name={"value"}
                    style={{ width: "100%", padding: "12px", height: "120px" }}
                ></textarea>
                <label for="ImageBig">{`Файл большого скриншота`}</label>
                <input
                    type="file"
                    id="ImageBig"
                    name="ImageBig"
                    onChange={(event) => {
                        setForm((prev) => ({ ...prev, ImageBig: event.target.files[0] }));
                    }}
                    style={{ marginBottom: "18px" }}
                />
                <label for="ImageSmall">{`Файл Миниатюры`}</label>
                <input
                    type="file"
                    id="ImageSmall"
                    name="ImageSmall"
                    onChange={(event) => {
                        setForm((prev) => ({ ...prev, ImageSmall: event.target.files[0] }));
                    }}
                    style={{ marginBottom: "18px" }}
                />
                <button
                    style={{
                        background: pending ? "gray" : "rgb(30,205,68)",
                        color: "white",
                        border: "none",
                        cursor: pending ? "not-allowed" : "pointer",
                    }}
                    onClick={sendCreate}
                    disabled={pending}
                >{`Добавить`}</button>
            </form>
        </div>
    );
};
const SectionScreenWorkerItem = ({ worker }) => {
    const initialForm = {
        name: worker.name,
        profession: worker.profession,
        id: worker.id,
    };
    const { fetchWorkers } = useContentContext();
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [form, setForm] = useState(initialForm);
    const [pending, setPending] = useState(false);

    const sendChange = async (event) => {
        event.preventDefault();
        setPending(true);

        const formData = new FormData();
        formData.append("name", form.name);
        formData.append("id", form.id);
        formData.append("profession", form.profession);
        formData.append("image", form.image);

        try {
            const res = await fetch(`${HOST}/workers`, {
                method: "PATCH",

                body: formData,
            });
            if (!res.ok) {
                setPending(false);
                return alert("Произошла ошибка при запросе");
            }
            await fetchWorkers();
            alert("Успешно!");
        } catch {
            alert("Произошла ошибка при запросе");
            setPending(false);
        }

        setPending(false);
    };
    return (
        <>
            <div
                style={{
                    display: "flex",
                    alignItems: "flex-start",
                    width: "100%",
                    justifyContent: "space-between",
                    borderRadius: "8px",
                    border: "1px solid black",
                    background: "white",
                    padding: "12px",
                    color: "black",
                }}
            >
                <div style={{ display: "flex", flexDirection: "column", gap: "8px", width: "100%" }}>
                    <p
                        style={{
                            fontWeight: "600",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        {`${worker.profession}`}

                        <button
                            onClick={() => {
                                if (!isFormOpen) {
                                    setIsFormOpen(true);
                                } else {
                                    setForm(initialForm);
                                    setIsFormOpen(false);
                                }
                            }}
                            style={{
                                background: "#1E4FCD",
                                color: "white",
                                border: "1px solid black",
                                borderRadius: "8px",
                            }}
                        >
                            {isFormOpen ? `Отмена` : `Изменить`}
                        </button>
                    </p>
                    <p>
                        <span style={{ fontSize: "18px" }}>{`${worker.name}`}</span>
                    </p>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "flex-start",
                            maxHeight: "320px",
                            overflow: "hidden",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <img
                            style={{
                                width: "300px",
                                height: "260px",
                                objectFit: "cover",
                                objectPosition: "top",
                                border: "1px solid black",
                            }}
                            src={worker.image}
                            alt=""
                        />
                    </div>
                </div>
            </div>
            {isFormOpen ? (
                <form
                    style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "column",
                        background: "#333",
                        padding: "8px",
                    }}
                >
                    <label for="name">{`Имя`}</label>
                    <input
                        onChange={(e) => {
                            setForm((prev) => ({ ...prev, name: e.target.value }));
                        }}
                        value={form.name}
                        name={"name"}
                        style={{ width: "100%", padding: "6px 4px" }}
                    ></input>
                    <label for="profession">{`Должность`}</label>
                    <input
                        onChange={(e) => {
                            setForm((prev) => ({ ...prev, profession: e.target.value }));
                        }}
                        value={form.profession}
                        name={"profession"}
                        style={{ width: "100%", padding: "6px 4px" }}
                    ></input>
                    <label for="image">{`Файл фото`}</label>
                    <input
                        type="file"
                        id="image"
                        name="image"
                        onChange={(event) => {
                            setForm((prev) => ({ ...prev, image: event.target.files[0] }));
                        }}
                        style={{ marginBottom: "18px" }}
                    />

                    <button
                        style={{
                            background: pending ? "gray" : "rgb(30,205,68)",
                            color: "white",
                            border: "none",
                            cursor: pending ? "not-allowed" : "pointer",
                        }}
                        onClick={sendChange}
                        disabled={pending}
                    >{`Изменить`}</button>
                </form>
            ) : null}
        </>
    );
};
const SectionScreenContactItem = ({ contact }) => {
    const initialForm = {
        name: contact.name,
        id: contact.id,
        value: contact.value,
    };
    const { fetchContacts } = useContentContext();
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [form, setForm] = useState(initialForm);
    const [pending, setPending] = useState(false);

    const sendChange = async (event) => {
        event.preventDefault();
        setPending(true);

        try {
            const res = await fetch(`${HOST}/contacts`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(form),
            });
            if (!res.ok) {
                setPending(false);
                return alert("Произошла ошибка при запросе");
            }
            await fetchContacts();
            alert("Успешно!");
        } catch (e) {
            console.error(e);
            alert("Произошла ошибка при запросе");
            setPending(false);
        }

        setPending(false);
    };
    return (
        <>
            <div
                style={{
                    display: "flex",
                    alignItems: "flex-start",
                    width: "100%",
                    justifyContent: "space-between",
                    borderRadius: "8px",
                    border: "1px solid black",
                    background: "white",
                    padding: "12px",
                    color: "black",
                }}
            >
                <div style={{ display: "flex", flexDirection: "column", gap: "8px", width: "100%" }}>
                    <button
                        onClick={() => {
                            if (!isFormOpen) {
                                setIsFormOpen(true);
                            } else {
                                setForm(initialForm);
                                setIsFormOpen(false);
                            }
                        }}
                        style={{
                            background: "#1E4FCD",
                            color: "white",
                            border: "1px solid black",
                            borderRadius: "8px",
                            width: "max-content",
                            alignSelf: "flex-end",
                        }}
                    >
                        {isFormOpen ? `Отмена` : `Изменить`}
                    </button>
                    <p>
                        <span style={{ fontSize: "18px" }}>{`${contact.name}`}</span>
                    </p>
                    <p>
                        <span style={{ fontSize: "18px" }}>{`${contact.value}`}</span>
                    </p>
                </div>
            </div>
            {isFormOpen ? (
                <form
                    style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "column",
                        background: "#333",
                        padding: "8px",
                    }}
                >
                    <label for="value">{`Ссылка`}</label>
                    <input
                        onChange={(e) => {
                            setForm((prev) => ({ ...prev, value: e.target.value }));
                        }}
                        value={form.value}
                        name={"value"}
                        style={{ width: "100%", padding: "6px 4px" }}
                    ></input>

                    <button
                        style={{
                            background: pending ? "gray" : "rgb(30,205,68)",
                            color: "white",
                            border: "none",
                            cursor: pending ? "not-allowed" : "pointer",
                        }}
                        onClick={sendChange}
                        disabled={pending}
                    >{`Изменить`}</button>
                </form>
            ) : null}
        </>
    );
};

const SectionScreen = ({ group, setSelectedSection }) => {
    const { getTextGroup, getCaseGroup, getWorkers, getContacts } = useContentContext();
    const groupObject =
        group.type === "text"
            ? getTextGroup(group.name)
            : group.type === "case"
            ? getCaseGroup(group.name)
            : group.type === "worker"
            ? getWorkers()
            : getContacts();

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
            }}
        >
            <h2 style={{ textAlign: "center" }}>{group.label}</h2>
            <button
                style={{
                    position: "fixed",
                    top: "12px",
                    left: "12px",

                    border: "1px solid black",

                    borderRadius: "8px",

                    padding: "4px 6px",

                    background: "#ccc",

                    color: "black",
                }}
                onClick={() => {
                    setSelectedSection(null);
                }}
            >
                {"Назад"}
            </button>
            {group.type === "case" ? <SectionScreenCaseCreate group={group.name} /> : null}
            {groupObject &&
                Object.keys(groupObject).map((groupObjectKey) => {
                    const groupObjectItem = groupObject[groupObjectKey];

                    return (
                        <>
                            {group.type === "text" ? (
                                <SectionScreenTextItem key={groupObjectKey} text={groupObjectItem} />
                            ) : group.type === "case" ? (
                                <>
                                    <SectionScreenCaseItem key={groupObjectKey} caseItem={groupObjectItem} />
                                </>
                            ) : group.type === "worker" ? (
                                <SectionScreenWorkerItem key={groupObjectKey} worker={groupObjectItem} />
                            ) : (
                                <SectionScreenContactItem key={groupObjectKey} contact={groupObjectItem} />
                            )}
                        </>
                    );
                })}
        </div>
    );
};

const SectionWrapperText = ({ setSelectedSection }) => {
    const { texts } = useContentContext();

    const formatedTexts = {};

    Object.keys(texts).forEach((textGroupKey) => {
        const textGroup = texts[textGroupKey];

        const firstKey = Object.keys(textGroup)[0];
        const firstText = textGroup[firstKey];

        const pageKey = firstText.page;

        if (!formatedTexts[pageKey]) {
            formatedTexts[pageKey] = {};
        }

        formatedTexts[pageKey][textGroupKey] = textGroup;
    });

    return (
        <>
            {Object.keys(formatedTexts).map((formatedTextKey) => {
                const formatedText = formatedTexts[formatedTextKey];

                const pageKey = Object.keys(formatedText)[0];
                const textPage = formatedText[pageKey];
                const groupKey = Object.keys(textPage)[0];
                const pageLabel = textPage[groupKey].pageLabel;

                return (
                    <>
                        <h3>{pageLabel}</h3>
                        {Object.keys(formatedText).map((textPageKey) => {
                            const textPage = formatedText[textPageKey];

                            const textPageFirstKey = Object.keys(textPage)[0];
                            const groupLabel = textPage[textPageFirstKey].groupLabel;
                            const group = textPage[textPageFirstKey].group;

                            return (
                                <Section
                                    onClick={() => {
                                        setSelectedSection({ name: group, label: groupLabel, type: "text" });
                                    }}
                                >
                                    {groupLabel}
                                </Section>
                            );
                        })}
                    </>
                );
            })}
        </>
    );
};

const AdminScreen = ({ token, setToken }) => {
    const [selectedSection, setSelectedSection] = useState(null);
    const [selectedType, setSelectedType] = useState("text");
    const navigate = useNavigate();

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                margin: "0 auto",
                maxWidth: "640px",
                width: "100%",
            }}
        >
            <button
                style={{
                    position: "fixed",
                    top: "12px",
                    right: "12px",

                    border: "1px solid black",

                    borderRadius: "8px",

                    padding: "4px 6px",

                    background: "#f33",

                    color: "white",
                }}
                onClick={() => {
                    setToken(undefined);
                    localStorage.removeItem("token");
                    navigate("/");
                }}
            >
                {"Выйти"}
            </button>
            {selectedSection ? (
                <SectionScreen setSelectedSection={setSelectedSection} group={selectedSection} />
            ) : (
                <>
                    <h2 style={{ textAlign: "center" }}>{`Администрирование, выберите раздел`}</h2>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <button
                            style={{
                                width: "100%",
                                padding: "4px 0",
                                border: "none",
                                background: selectedType === "text" ? "#1E4FCD" : "white",
                                color: selectedType === "text" ? "white" : "black",
                            }}
                            onClick={() => {
                                setSelectedType("text");
                            }}
                        >{`Тексты`}</button>
                        <button
                            style={{
                                width: "100%",
                                padding: "4px 0",
                                border: "none",
                                background: selectedType === "case" ? "#1E4FCD" : "white",
                                color: selectedType === "case" ? "white" : "black",
                            }}
                            onClick={() => {
                                setSelectedType("case");
                            }}
                        >{`Кейсы`}</button>
                        <button
                            style={{
                                width: "100%",
                                padding: "4px 0",
                                border: "none",
                                background: selectedType === "worker" ? "#1E4FCD" : "white",
                                color: selectedType === "worker" ? "white" : "black",
                            }}
                            onClick={() => {
                                setSelectedType("worker");
                            }}
                        >{`Сотрудники`}</button>
                        <button
                            style={{
                                width: "100%",
                                padding: "4px 0",
                                border: "none",
                                background: selectedType === "contact" ? "#1E4FCD" : "white",
                                color: selectedType === "contact" ? "white" : "black",
                            }}
                            onClick={() => {
                                setSelectedType("contact");
                            }}
                        >{`Контакты`}</button>
                    </div>
                    {separator}

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            gap: "8px",
                        }}
                    >
                        {selectedType === "text" ? (
                            <>
                                <SectionWrapperText setSelectedSection={setSelectedSection} />
                            </>
                        ) : selectedType === "case" ? (
                            <>
                                <Section
                                    onClick={() => {
                                        setSelectedSection({ name: "landing", label: "Лэндинги", type: "case" });
                                    }}
                                >{`Лэндинги`}</Section>
                                <Section
                                    onClick={() => {
                                        setSelectedSection({ name: "shop", label: "Онлайн магазины", type: "case" });
                                    }}
                                >{`Онлайн магазины`}</Section>
                                <Section
                                    onClick={() => {
                                        setSelectedSection({
                                            name: "multi",
                                            label: "Многостраничные сайты",
                                            type: "case",
                                        });
                                    }}
                                >{`Многостраничные сайты`}</Section>
                            </>
                        ) : selectedType === "worker" ? (
                            <>
                                <Section
                                    onClick={() => {
                                        setSelectedSection({ name: "workers", label: "Команда", type: "worker" });
                                    }}
                                >{`Перейти к сотрудникам`}</Section>
                            </>
                        ) : (
                            <>
                                <Section
                                    onClick={() => {
                                        setSelectedSection({ name: "contacts", label: "Контакты", type: "contact" });
                                    }}
                                >{`Перейти к контактам`}</Section>
                            </>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export const AdminPage = () => {
    const [token, setToken] = useState(undefined);

    useEffect(() => {
        const token = localStorage.getItem("token");
        setToken(token);
    }, []);

    return (
        <main style={{ background: "#1A1A1A", minHeight: "100svh", color: "white", paddingTop: "120px" }}>
            {typeof token !== "undefined" ? (
                token ? (
                    <AdminScreen setToken={setToken} token={token} />
                ) : (
                    <LoginScreen setToken={setToken} />
                )
            ) : (
                <div style={{ position: "fixed", top: "50%", left: "48%", fontSize: "18px" }}>Загрузка...</div>
            )}
        </main>
    );
};
