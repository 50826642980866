import React from "react";
import { useContentContext } from "../../../providers/Content.provider";
import styles from "./Stages.module.css";

const Stages = () => {
    const { getTextGroup } = useContentContext();

    const textGroup = getTextGroup("parsing_worksteps");
    return (
        <section className={styles.stages}>
            <div className="container">
                <h2 className={styles.title}>
                    {textGroup.heading.value}{" "}
                    <span className={styles.blue}>
                        ;<span className={styles.black}>-</span>]
                    </span>
                </h2>
                <p className={styles.descr}>{textGroup.subheading.value}</p>
                <div className={styles.gridWrapper}>
                    <div className={styles.gridItem}>
                        <div className={styles.flexWrapper}>
                            <div className={styles.content}>
                                <h3 className={styles.subtitle}>{textGroup.step_1_title.value}</h3>
                                <p className={styles.text}>{textGroup.step_1_text.value}</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.gridItem}>
                        <div className={styles.flexWrapper}>
                            <div className={styles.content}>
                                <h3 className={styles.subtitle}>{textGroup.step_2_title.value}</h3>
                                <p className={styles.text}>{textGroup.step_2_text.value}</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.gridItem}>
                        <div className={styles.flexWrapper}>
                            <div className={styles.content}>
                                <h3 className={styles.subtitle}>{textGroup.step_3_title.value}</h3>
                                <p className={styles.text}>{textGroup.step_3_text.value}</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.gridItem}>
                        <div className={styles.flexWrapper}>
                            <div className={styles.content}>
                                <h3 className={styles.subtitle}>{textGroup.step_4_title.value}</h3>
                                <p className={styles.text}>{textGroup.step_4_text.value}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Stages;
