import React from 'react';
import Content from "../components/Policy/Content";

const PolicyPage = () => {
    return (
        <>
            <Content />
        </>
    );
};

export default PolicyPage;