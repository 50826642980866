import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { HOST } from "../constants";
import case1 from "../images/cases/case1.jpg";
import case10 from "../images/cases/case10.jpg";
import case11 from "../images/cases/case11.jpg";
import case12 from "../images/cases/case12.jpg";
import case13 from "../images/cases/case13.jpg";
import case14 from "../images/cases/case14.jpg";
import case15 from "../images/cases/case15.jpg";
import case16 from "../images/cases/case16.jpg";
import case17 from "../images/cases/case17.jpg";
import case18 from "../images/cases/case18.jpg";
import case19 from "../images/cases/case19.jpg";
import case2 from "../images/cases/case2.jpg";
import case20 from "../images/cases/case20.jpg";
import case21 from "../images/cases/case21.jpg";
import case22 from "../images/cases/case22.jpg";
import case23 from "../images/cases/case23.jpg";
import case24 from "../images/cases/case24.jpg";
import case3 from "../images/cases/case3.jpg";
import case4 from "../images/cases/case4.jpg";
import case5 from "../images/cases/case5.jpg";
import case6 from "../images/cases/case6.jpg";
import case7 from "../images/cases/case7.jpg";
import case8 from "../images/cases/case8.jpg";
import case9 from "../images/cases/case9.jpg";
import daniil from "../images/daniil.jpg";
import developer from "../images/developer.jpg";
import egor from "../images/egor.jpg";
import maxim from "../images/maxim.jpg";
import mini1 from "../images/mini1.jpg";
import mini10 from "../images/mini10.jpg";
import mini11 from "../images/mini11.jpg";
import mini12 from "../images/mini12.jpg";
import mini13 from "../images/mini13.jpg";
import mini14 from "../images/mini14.jpg";
import mini15 from "../images/mini15.jpg";
import mini16 from "../images/mini16.jpg";
import mini17 from "../images/mini17.jpg";
import mini18 from "../images/mini18.jpg";
import mini19 from "../images/mini19.jpg";
import mini2 from "../images/mini2.jpg";
import mini20 from "../images/mini20.jpg";
import mini21 from "../images/mini21.jpg";
import mini22 from "../images/mini22.jpg";
import mini23 from "../images/mini23.jpg";
import mini24 from "../images/mini24.jpg";
import mini3 from "../images/mini3.jpg";
import mini4 from "../images/mini4.jpg";
import mini5 from "../images/mini5.jpg";
import mini6 from "../images/mini6.jpg";
import mini7 from "../images/mini7.jpg";
import mini8 from "../images/mini8.jpg";
import mini9 from "../images/mini9.jpg";
import natalya from "../images/natalya.jpg";
import nikolai from "../images/nikolai.jpg";

const textsFallback = [
    //*@Общие

    //@Навигация

    {
        pageLabel: "Общие",
        page: "all",
        groupLabel: "Навигация",
        group: "navigation",
        name: "phone",
        label: "Номер телефона",
        value: "+79811036538",
    },
    {
        pageLabel: "Общие",
        page: "all",
        groupLabel: "Навигация",
        group: "navigation",
        name: "city",
        label: "Город",
        value: "г. Санкт-Петербург",
    },
    {
        pageLabel: "Общие",
        page: "all",
        groupLabel: "Навигация",
        group: "navigation",
        name: "email",
        label: "Почта",
        value: "cube80ag@gmail.com",
    },

    //@Обратная связь

    {
        pageLabel: "Общие",
        page: "all",
        groupLabel: "Обратная связь",
        group: "feedback",
        name: "heading",
        label: "заголовок",
        value: "Остались вопросы?",
    },
    {
        pageLabel: "Общие",
        page: "all",
        groupLabel: "Обратная связь",
        group: "feedback",
        name: "subheading",
        label: "подзаголовок",
        value: "Пишите или звоните. Отвечаем круглосуточно.",
    },

    {
        pageLabel: "Общие",
        page: "all",
        groupLabel: "Обратная связь",
        group: "feedback",
        name: "agree",
        label: "согласие перс. данные",
        value: 'Нажимая "Получить консультацию", вы даете согласие наобработку персональных данных.',
    },
    {
        pageLabel: "Общие",
        page: "all",
        groupLabel: "Обратная связь",
        group: "feedback",
        name: "submit",
        label: "кнопка отправки",
        value: "Получить консультацию",
    },

    {
        pageLabel: "Общие",
        page: "all",
        groupLabel: "Обратная связь",
        group: "feedback",
        name: "email",
        label: "email",
        value: "cube80ag@gmail.com",
    },

    //@Футер

    {
        pageLabel: "Общие",
        page: "all",
        groupLabel: "Футер",
        group: "footer",
        name: "email",
        label: "Футер email",
        value: "cube80ag@gmail.com",
    },

    //*@Главная

    //@Хеадер

    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Хеадер",
        group: "header",
        name: "heading_1",
        label: "Маленький заголовок",
        value: "Делаем не просто сайты, а изучаем конкурентов и строим стратегию продвижения",
    },

    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Хеадер",
        group: "header",
        name: "heading_2",
        label: "Большой заголовок",
        value: "Сайты и маркетинг под ключ",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Хеадер",
        group: "header",
        name: "heading_3.1",
        label: "Динамичный заголовок 1",
        value: "web.сервисы",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Хеадер",
        group: "header",
        name: "heading_3.2",
        label: "Динамичный заголовок 2",
        value: "брендинг",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Хеадер",
        group: "header",
        name: "heading_3.3",
        label: "Динамичный заголовок 3",
        value: "стратегия",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Хеадер",
        group: "header",
        name: "heading_3.4",
        label: "Динамичный заголовок 4",
        value: "фир.стиль",
    },

    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Хеадер",
        group: "header",
        name: "product_1",
        label: "Вид проекта 1",
        value: "websites",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Хеадер",
        group: "header",
        name: "product_2",
        label: "Вид проекта 2",
        value: "applications",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Хеадер",
        group: "header",
        name: "product_3",
        label: "Вид проекта 3",
        value: "marketing",
    },
    { pageLabel: "Главная", page: "home", groupLabel: "Хеадер", group: "header", name: "product_4", label: "Вид проекта 4", value: "smm" },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Хеадер",
        group: "header",
        name: "product_5",
        label: "Вид проекта 5",
        value: "webdevelopment",
    },
    { pageLabel: "Главная", page: "home", groupLabel: "Хеадер", group: "header", name: "product_6", label: "Вид проекта 6", value: "seo" },

    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Хеадер",
        group: "header",
        name: "tech_1",
        label: "Технология 1 (первый столбец)",
        value: "Python",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Хеадер",
        group: "header",
        name: "tech_2",
        label: "Технология 2 (первый столбец)",
        value: "JavaScript",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Хеадер",
        group: "header",
        name: "tech_3",
        label: "Технология 3 (первый столбец)",
        value: "Node",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Хеадер",
        group: "header",
        name: "tech_4",
        label: "Технология 4 (второй столбец)",
        value: "Django",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Хеадер",
        group: "header",
        name: "tech_5",
        label: "Технология 5 (второй столбец)",
        value: "React",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Хеадер",
        group: "header",
        name: "tech_6",
        label: "Технология 6 (второй столбец)",
        value: "Next",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Хеадер",
        group: "header",
        name: "tech_7",
        label: "Технология 7 (второй столбец)",
        value: "Vue",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Хеадер",
        group: "header",
        name: "tech_8",
        label: "Технология 8 (третий столбец)",
        value: "Wordpress",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Хеадер",
        group: "header",
        name: "tech_9",
        label: "Технология 9 (третий столбец)",
        value: "Tilda",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Хеадер",
        group: "header",
        name: "tech_10",
        label: "Технология 10 (третий столбец)",
        value: "Ghost",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Хеадер",
        group: "header",
        name: "tech_11",
        label: "Технология 11 (третий столбец)",
        value: "Others...",
    },

    //@Почему мы

    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Почему мы",
        group: "whywe",
        name: "heading_1",
        label: "Заголовок 1",
        value: "Почему",
    },
    { pageLabel: "Главная", page: "home", groupLabel: "Почему мы", group: "whywe", name: "heading_2", label: "Заголовок 2", value: "мы" },

    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Почему мы",
        group: "whywe",
        name: "argument_1_title",
        label: "заголовок аргумента 1",
        value: "Никаких операторов по продажам",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Почему мы",
        group: "whywe",
        name: "argument_1_text",
        label: "аргумент 1",
        value: "Все сотрудники и менеджеры проектов имеют профильное образование в сайтостроении. Вам не придется общаться с “продавцами утюгов”.",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Почему мы",
        group: "whywe",
        name: "argument_2_title",
        label: "заголовок аргумента 2",
        value: "Надежнее фриланса",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Почему мы",
        group: "whywe",
        name: "argument_2_text",
        label: "аргумент 2",
        value: "Больше половины клиентов фрилансеров получают неработающие сайты, либо платят мошенникам. С нами вы забудете про эти проблемы.",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Почему мы",
        group: "whywe",
        name: "argument_3_title",
        label: "заголовок аргумента 3",
        value: "Приведем клиентов",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Почему мы",
        group: "whywe",
        name: "argument_3_text",
        label: "аргумент 3",
        value: "Если сайт не приносит прибыли - цена ему “ноль”. Настраиваем рекламу и приводим реальных заказчиков с деньгами.",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Почему мы",
        group: "whywe",
        name: "argument_4_title",
        label: "заголовок аргумента 4",
        value: "Анализируем конкурентов",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Почему мы",
        group: "whywe",
        name: "argument_4_text",
        label: "аргумент 4",
        value: "Разработчики-любители сдают вместо сайтов красивые картинки. Мы сперва изучаем вашу нишу и делаем по-настоящему продающий сайт.",
    },

    //@Этапы работы

    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Этапы работы",
        group: "worksteps",
        name: "heading",
        label: "заголовок",
        value: "Этапы работы",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Этапы работы",
        group: "worksteps",
        name: "subheading",
        label: "подзаголовок",
        value: "Изучим вашу нишу, определим стратегию работы будущего бизнеса и на ее основе соберем продающий сайт. Все работы выполняются под ключ.",
    },

    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Этапы работы",
        group: "worksteps",
        name: "step_1_title",
        label: "заголовок 1",
        value: "БЕСПЛАТНАЯ КОНСУЛЬТАЦИЯ",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Этапы работы",
        group: "worksteps",
        name: "step_1_text",
        label: "текст 1",
        value: "Выбор типа сайта, определение методов продвижения. Базовый маркетинговый анализ ниши и конкурентов.",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Этапы работы",
        group: "worksteps",
        name: "step_2_title",
        label: "заголовок 2",
        value: "ТЕХЗАДАНИЕ И ПРЕДОПЛАТА",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Этапы работы",
        group: "worksteps",
        name: "step_2_text",
        label: "текст 2",
        value: "Составление техзадания, набросок структуры сайта. Договоренность об условиях оплаты.",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Этапы работы",
        group: "worksteps",
        name: "step_3_title",
        label: "заголовок 3",
        value: "МАРКЕТИНГОВЫЙ АНАЛИЗ",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Этапы работы",
        group: "worksteps",
        name: "step_3_text",
        label: "текст 3",
        value: "Выявление страхов заказчиков и методов их закрытия. Составление портрета аудитории. Анализ конкурентов.",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Этапы работы",
        group: "worksteps",
        name: "step_4_title",
        label: "заголовок 4",
        value: "ОТРИСОВКА ДИЗАЙНА",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Этапы работы",
        group: "worksteps",
        name: "step_4_text",
        label: "текст 4",
        value: "Создание прототипа сайта и его отрисовка. Проработка мобильной и планшетной версий.",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Этапы работы",
        group: "worksteps",
        name: "step_5_title",
        label: "заголовок 5",
        value: "ВЕРСТКА И РАЗРАБОТКА",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Этапы работы",
        group: "worksteps",
        name: "step_5_text",
        label: "текст 5",
        value: "Выбор системы управления сайтом. Адаптивная верстка под различные экраны. Разработка функционала.",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Этапы работы",
        group: "worksteps",
        name: "step_6_title",
        label: "заголовок 6",
        value: "ТЕСТЫ И ЗАПУСК САЙТА",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Этапы работы",
        group: "worksteps",
        name: "step_6_text",
        label: "текст 6",
        value: "Загрузка сайта на хостинг, подключение домена, почты, счетчика посещений. Внесение остатка оплаты.",
    },

    //@Услуги

    { pageLabel: "Главная", page: "home", groupLabel: "Услуги", group: "services", name: "heading", label: "заголовок", value: "Услуги" },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Услуги",
        group: "services",
        name: "subheading",
        label: "подзаголовок",
        value: "Предоставим вашему бизнесу комплекс услуг по развитию и продвижению. Создадим техническую часть вашего бизнеса, займемся брендингом и рекламой.",
    },

    //@Кейсы и цены общий заголовок

    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены общий заголовок",
        group: "caseshead",
        name: "heading",
        label: "заголовок",
        value: "Кейсы и цены",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены общий заголовок",
        group: "caseshead",
        name: "subheading",
        label: "подзаголовок",
        value: "Создаем магазины, лендинги, корпоративные сайты, криптосервисы, сайты мероприятий, а также сайты под нестандартные задачи.",
    },

    //@Кейсы и цены лэндинги

    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены лэндинги",
        group: "caseslanding",
        name: "heading",
        label: "заголовок",
        value: "ЛЕНДИНГИ ВИЗИТКИ",
    },

    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены лэндинги",
        group: "caseslanding",
        name: "price_1",
        label: "Цена 1",
        value: "Стоимость от",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены лэндинги",
        group: "caseslanding",
        name: "price 2",
        label: "Цена 2",
        value: "30 тыс. руб.",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены лэндинги",
        group: "caseslanding",
        name: "price_time",
        label: "Цена срок",
        value: "Срок выполнения от 2 недель",
    },

    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены лэндинги",
        group: "caseslanding",
        name: "option_1",
        label: "возможность 1",
        value: "Подключение статистики",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены лэндинги",
        group: "caseslanding",
        name: "option_2",
        label: "возможность 2",
        value: "Анализ конкурентов",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены лэндинги",
        group: "caseslanding",
        name: "option_3",
        label: "возможность 3",
        value: "Система управления сайтом",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены лэндинги",
        group: "caseslanding",
        name: "option_4",
        label: "возможность 4",
        value: "Возможность “мультистраничности”",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены лэндинги",
        group: "caseslanding",
        name: "option_5",
        label: "возможность 5",
        value: "Индивидуальный дизайн",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены лэндинги",
        group: "caseslanding",
        name: "option_6",
        label: "возможность 6",
        value: "Подключение статистики",
    },

    //@Кейсы и цены онлайн магазины

    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены онлайн магазины",
        group: "casesshop",
        name: "heading",
        label: "заголовок",
        value: "ONLINE МАГАЗИНЫ",
    },

    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены онлайн магазины",
        group: "casesshop",
        name: "price_1",
        label: "Цена 1",
        value: "Стоимость от",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены онлайн магазины",
        group: "casesshop",
        name: "price 2",
        label: "Цена 2",
        value: "70 тыс. руб.",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены онлайн магазины",
        group: "casesshop",
        name: "price_time",
        label: "Цена срок",
        value: "Срок выполнения от 2 недель",
    },

    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены онлайн магазины",
        group: "casesshop",
        name: "option_1",
        label: "возможность 1",
        value: "Интеграция с вашими базами данных",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены онлайн магазины",
        group: "casesshop",
        name: "option_2",
        label: "возможность 2",
        value: "Система управления сайтом",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены онлайн магазины",
        group: "casesshop",
        name: "option_3",
        label: "возможность 3",
        value: "Автоматические продажи",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены онлайн магазины",
        group: "casesshop",
        name: "option_4",
        label: "возможность 4",
        value: "Простота доработок",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены онлайн магазины",
        group: "casesshop",
        name: "option_5",
        label: "возможность 5",
        value: "Никаких шаблонных решений",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены онлайн магазины",
        group: "casesshop",
        name: "option_6",
        label: "возможность 6",
        value: "Система акций и промокодов",
    },

    //@Кейсы и цены мультистраничники

    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены мультистраничники",
        group: "casesmulti",
        name: "heading_1",
        label: "заголовок 1",
        value: "МНОГОСТРАНИЧНЫЕ",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены мультистраничники",
        group: "casesmulti",
        name: "heading_2",
        label: "заголовок 2",
        value: "САЙТЫ",
    },

    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены мультистраничники",
        group: "casesmulti",
        name: "price_1",
        label: "Цена 1",
        value: "Стоимость от",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены мультистраничники",
        group: "casesmulti",
        name: "price 2",
        label: "Цена 2",
        value: "50 тыс. руб.",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены мультистраничники",
        group: "casesmulti",
        name: "price_time",
        label: "Цена срок",
        value: "Срок выполнения от 2 недель",
    },

    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены мультистраничники",
        group: "casesmulti",
        name: "option_1",
        label: "возможность 1",
        value: "Сложные web-сервисы",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены мультистраничники",
        group: "casesmulti",
        name: "option_2",
        label: "возможность 2",
        value: "Корпоративная стилистика",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены мультистраничники",
        group: "casesmulti",
        name: "option_3",
        label: "возможность 3",
        value: "SEO-ориентированность",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены мультистраничники",
        group: "casesmulti",
        name: "option_4",
        label: "возможность 4",
        value: "Система управления сайтом",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены мультистраничники",
        group: "casesmulti",
        name: "option_5",
        label: "возможность 5",
        value: "Простота масштабируемости",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Кейсы и цены мультистраничники",
        group: "casesmulti",
        name: "option_6",
        label: "возможность 6",
        value: "Привычный пользователю формат",
    },

    //@FAQ

    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "FAQ",
        group: "faq",
        name: "heading",
        label: "заголовок",
        value: "Если вы не нашли ответа на свой вопрос, напишите нам и мы ответим в течение 10 минут.",
    },

    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "FAQ",
        group: "faq",
        name: "question_1",
        label: "вопрос 1",
        value: "Как происходит оплата работы?",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "FAQ",
        group: "faq",
        name: "answer_1",
        label: "ответ 1",
        value: "Вы платите за работу поэтапно. Сперва незначительный аванс, после чего мы показываем вам выполненные этапы, за каждый из которых вы вносите следующий платеж.",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "FAQ",
        group: "faq",
        name: "question_2",
        label: "вопрос 2",
        value: "С чего начинается ваша работа?",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "FAQ",
        group: "faq",
        name: "answer_2",
        label: "ответ 2",
        value: "Перед началом создания сайта мы изучаем вашу нишу и конкурентов. На основе этого анализа составляем техническое задание, в котором расписываем наиболее удачную структуру сайта.",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "FAQ",
        group: "faq",
        name: "question_3",
        label: "вопрос 3",
        value: "Предоставляете скидки?",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "FAQ",
        group: "faq",
        name: "answer_3",
        label: "ответ 3",
        value: "Мы можем оптимизировать перечень работ и за счет удаления трудозатратных и необязательных этапов снизить общую цену проекта. Также мы можем дать вам рассрочку на оплату.",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "FAQ",
        group: "faq",
        name: "question_4",
        label: "вопрос 4",
        value: "А если сайт сломается после полной оплаты?",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "FAQ",
        group: "faq",
        name: "answer_4",
        label: "ответ 4",
        value: "В течение месяца мы гарантируем вам бесплатные техническую поддержку и исправление выявленных ошибок. Мы остаемся на связи 7 дней в неделю и никогда не пропадаем.",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "FAQ",
        group: "faq",
        name: "question_5",
        label: "вопрос 5",
        value: "Почему вы берете дороже фриланса?",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "FAQ",
        group: "faq",
        name: "answer_5",
        label: "ответ 5",
        value: 'В отличие от фриланса мы не рисуем просто "красивую картинку". Мы изучаем рынок и делаем сайт, который приносит прибыль. Также вы должны учитывать, что фрилансеры часто сдают нерабочие сайты, пропадают со связи, а также вы рискуете столкнуться с мошенниками.',
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "FAQ",
        group: "faq",
        name: "question_6",
        label: "вопрос 6",
        value: "Можете поменять нам систему управления сайтом?",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "FAQ",
        group: "faq",
        name: "answer_6",
        label: "ответ 6",
        value: "Да, но вы должны учитывать, что при смене системы управления может потребоваться доработка сайта под нее. Также некоторые системы управления не предусматривают простого переноса сайта.",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "FAQ",
        group: "faq",
        name: "question_7",
        label: "вопрос 7",
        value: "Делаете сайты на шаблонах?",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "FAQ",
        group: "faq",
        name: "answer_7",
        label: "ответ 7",
        value: "Занимаемся этим в исключительно редких случаях. Шаблоны зачастую очень сильно тормозят сайт, либо сделаны с грубыми ошибками, не имеют возможности гибкой смены структуры сайта.",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "FAQ",
        group: "faq",
        name: "question_8",
        label: "вопрос 8",
        value: "Доделаете работу за другим разработчиком?",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "FAQ",
        group: "faq",
        name: "answer_8",
        label: "ответ 8",
        value: "Если код вашего разработчика пригоден для работы и не нашпигован ошибками, то мы готовы этим заняться.",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "FAQ",
        group: "faq",
        name: "question_9",
        label: "вопрос 9",
        value: "Занимаетесь техподдержкой?",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "FAQ",
        group: "faq",
        name: "answer_9",
        label: "ответ 9",
        value: "Да, после сдачи готовы заняться полным продвижением вашего бизнеса, а также технической поддержкой сайта, его доработкой и масштабированием.",
    },

    //@Об агенстве

    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Об агенстве",
        group: "aboutus",
        name: "heading",
        label: "заголовок",
        value: "Об агенстве",
    },
    {
        pageLabel: "Главная",
        page: "home",
        groupLabel: "Об агенстве",
        group: "aboutus",
        name: "text",
        label: "текст",
        value: "В команде работают специалисты по разработке, маркетингу, дизайну, а также продвижению и рекламе. За 5 лет работы пройден путь от 2 человек до агентства полного цикла, которое закрывает все потребности клиентов по разработке IT продуктов и их продвижению.",
    },

    //*@Контекст

    //@Хеадер

    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Хеадер",
        group: "context_header",
        name: "heading_1",
        label: "заголовок 1",
        value: "DIGITAL-агентство полного цикла Cube80",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Хеадер",
        group: "context_header",
        name: "heading_2",
        label: "заголовок 2",
        value: "Контекстная реклама",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Хеадер",
        group: "context_header",
        name: "heading_3",
        label: "заголовок 3",
        value: "Настроим контекстную рекламу в Google или Yandex. Две недели ведения бесплатно.",
    },

    //@Включает

    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Включает",
        group: "context_includes",
        name: "heading",
        label: "заголовок",
        value: "Что включает",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Включает",
        group: "context_includes",
        name: "subheading",
        label: "подзаголовок",
        value: "Контекстная реклама - самый популярный и быстрый способ найти первых клиентов из поисковых систем.",
    },

    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Включает",
        group: "context_includes",
        name: "option_1_heading",
        label: "возможность 1 заголовок",
        value: "ЦЕЛЕВОЙ ТРАФИК",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Включает",
        group: "context_includes",
        name: "option_1_text",
        label: "возможность 1 текст",
        value: "Реклама показывается только аудитории, которая заинтересована вашим товаром.",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Включает",
        group: "context_includes",
        name: "option_2_heading",
        label: "возможность 2 заголовок",
        value: "БЫСТРЫЙ РЕЗУЛЬТАТ",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Включает",
        group: "context_includes",
        name: "option_2_text",
        label: "возможность 2 текст",
        value: "Вам не надо ждать повышения позиций вашего сайта в поиске. Клиенты приходят сразу же.",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Включает",
        group: "context_includes",
        name: "option_3_heading",
        label: "возможность 3 заголовок",
        value: "ДЕШЕВЫЕ КЛИЕНТЫ",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Включает",
        group: "context_includes",
        name: "option_3_text",
        label: "возможность 3 текст",
        value: "Стоимость привлечения покупателя минимальна. Затраты зависят от конкуренции в нише.",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Включает",
        group: "context_includes",
        name: "option_4_heading",
        label: "возможность 4 заголовок",
        value: "МОНИТОРИНГ КОНКУРЕНТОВ",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Включает",
        group: "context_includes",
        name: "option_4_text",
        label: "возможность 4 текст",
        value: "Инструменты анализа конкурентов показывают по каким запросам они продвигаются.",
    },

    //@Этапы работы

    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Этапы работы",
        group: "context_worksteps",
        name: "heading",
        label: "заголовок",
        value: "Этапы работы",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Этапы работы",
        group: "context_worksteps",
        name: "subheading",
        label: "подзаголовок",
        value: "Изучим вашу нишу, определим стратегию работы будущего бизнеса и на ее основе соберем продающий сайт. Все работы выполняются под ключ.",
    },

    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Этапы работы",
        group: "context_worksteps",
        name: "step_1_title",
        label: "заголовок 1",
        value: "БЕСПЛАТНАЯ КОНСУЛЬТАЦИЯ",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Этапы работы",
        group: "context_worksteps",
        name: "step_1_text",
        label: "текст 1",
        value: "Выясняем целесообразность контекстной рекламы. Определение бюджета и ожиданий от рекламы.",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Этапы работы",
        group: "context_worksteps",
        name: "step_2_title",
        label: "заголовок 2",
        value: "ЧАСТИЧНАЯ ПРЕДОПЛАТА",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Этапы работы",
        group: "context_worksteps",
        name: "step_2_text",
        label: "текст 2",
        value: "Подписание договора, либо устная договоренность об условиях сдачи и оплаты работы.",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Этапы работы",
        group: "context_worksteps",
        name: "step_3_title",
        label: "заголовок 3",
        value: "АНАЛИЗ НИШИ",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Этапы работы",
        group: "context_worksteps",
        name: "step_3_text",
        label: "текст 3",
        value: "Анализ конкурентов в вашей сфере. Составление набора ключевых слов для показа рекламы.",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Этапы работы",
        group: "context_worksteps",
        name: "step_4_title",
        label: "заголовок 4",
        value: "СОЗДАНИЕ ОБЪЯВЛЕНИЙ",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Этапы работы",
        group: "context_worksteps",
        name: "step_4_text",
        label: "текст 4",
        value: "Создание набора баннеров для рекламной кампании (без отрисовки), либо текстовых объявлений.",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Этапы работы",
        group: "context_worksteps",
        name: "step_5_title",
        label: "заголовок 5",
        value: "ТЕСТЫ И УДЕШЕВЛЕНИЕ",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Этапы работы",
        group: "context_worksteps",
        name: "step_5_text",
        label: "текст 5",
        value: "Тестирование рекламы, проверка ключевых слов на отклик аудитории. Уменьшение стоимости показов.",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Этапы работы",
        group: "context_worksteps",
        name: "step_6_title",
        label: "заголовок 6",
        value: "ЗАПУСК И ОТЧЕТ",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Этапы работы",
        group: "context_worksteps",
        name: "step_6_text",
        label: "текст 6",
        value: "Отчет по статистике показов, количеству переходов. Прогноз продвижения. Оплата за работу.",
    },

    //@Цены

    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Цены",
        group: "context_prices",
        name: "heading",
        label: "заголовок",
        value: "Цены",
    },

    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Цены",
        group: "context_prices",
        name: "variant_1_title",
        label: "вариант 1 заголовок",
        value: "GOOGLE ADWORDS",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Цены",
        group: "context_prices",
        name: "variant_1_price",
        label: "вариант 1 цена",
        value: "20 000 руб.",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Цены",
        group: "context_prices",
        name: "variant_1_option_1",
        label: "вариант 1 возможность 1",
        value: "Анализ конкурентов",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Цены",
        group: "context_prices",
        name: "variant_1_option_2",
        label: "вариант 1 возможность 2",
        value: "Настройка статистики",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Цены",
        group: "context_prices",
        name: "variant_1_option_3",
        label: "вариант 1 возможность 3",
        value: "Оптимизация расходов",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Цены",
        group: "context_prices",
        name: "variant_1_option_4",
        label: "вариант 1 возможность 4",
        value: "Подбор ключевых слов",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Цены",
        group: "context_prices",
        name: "variant_1_option_5",
        label: "вариант 1 возможность 5",
        value: "Определение болей клиентов",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Цены",
        group: "context_prices",
        name: "variant_1_option_6",
        label: "вариант 1 возможность 6",
        value: "Отчет и прогноз прибыли",
    },

    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Цены",
        group: "context_prices",
        name: "variant_2_title",
        label: "вариант 2 заголовок",
        value: "ЯНДЕКС. ДИРЕКТ",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Цены",
        group: "context_prices",
        name: "variant_2_price",
        label: "вариант 2 цена",
        value: "20 000 руб.",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Цены",
        group: "context_prices",
        name: "variant_2_option_1",
        label: "вариант 2 возможность 1",
        value: "Анализ конкурентов",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Цены",
        group: "context_prices",
        name: "variant_2_option_2",
        label: "вариант 2 возможность 2",
        value: "Настройка статистики",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Цены",
        group: "context_prices",
        name: "variant_2_option_3",
        label: "вариант 2 возможность 3",
        value: "Оптимизация расходов",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Цены",
        group: "context_prices",
        name: "variant_2_option_4",
        label: "вариант 2 возможность 4",
        value: "Подбор ключевых слов",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Цены",
        group: "context_prices",
        name: "variant_2_option_5",
        label: "вариант 2 возможность 5",
        value: "Определение болей клиентов",
    },
    {
        pageLabel: "Контекст",
        page: "context",
        groupLabel: "Цены",
        group: "context_prices",
        name: "variant_2_option_6",
        label: "вариант 2 возможность 6",
        value: "Отчет и прогноз прибыли",
    },

    //*@SEO

    //@Хеадер

    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Хеадер",
        group: "seo_header",
        name: "heading_1",
        label: "заголовок 1",
        value: "DIGITAL-агентство полного цикла Cube80",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Хеадер",
        group: "seo_header",
        name: "heading_2",
        label: "заголовок 2",
        value: "SEO продвижение",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Хеадер",
        group: "seo_header",
        name: "heading_3",
        label: "заголовок 3",
        value: "Повысим позиции вашего сайта в поисковой выдаче и оптимизируем его SEO.",
    },

    //@Включает

    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Включает",
        group: "seo_includes",
        name: "heading",
        label: "заголовок",
        value: "Что включает",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Включает",
        group: "seo_includes",
        name: "subheading",
        label: "подзаголовок",
        value: "SEO оптимизированный сайт приводит клиентов не сразу, однако результат окупает себя в долгосрочной перспективе.",
    },

    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Включает",
        group: "seo_includes",
        name: "option_1_heading",
        label: "возможность 1 заголовок",
        value: "БЕСПЛАТНЫЕ КЛИЕНТЫ",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Включает",
        group: "seo_includes",
        name: "option_1_text",
        label: "возможность 1 текст",
        value: "Клиенты находят вас сами через бесплатные запросы в поиске.",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Включает",
        group: "seo_includes",
        name: "option_2_heading",
        label: "возможность 2 заголовок",
        value: "ВЫСОКИЙ ПРОЦЕНТ ПРОДАЖ",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Включает",
        group: "seo_includes",
        name: "option_2_text",
        label: "возможность 2 текст",
        value: "Люди больше верят сайтам, которые выданы алгоритмом поиска.",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Включает",
        group: "seo_includes",
        name: "option_3_heading",
        label: "возможность 3 заголовок",
        value: "ДОВЕРИЕ К БРЕНДУ",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Включает",
        group: "seo_includes",
        name: "option_3_text",
        label: "возможность 3 текст",
        value: "Посетители постоянно видят ваш сайт в поисковиках и привыкают к нему.",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Включает",
        group: "seo_includes",
        name: "option_4_heading",
        label: "возможность 4 заголовок",
        value: "ДОЛГОСРОЧНЫЙ РЕЗУЛЬТАТ",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Включает",
        group: "seo_includes",
        name: "option_4_text",
        label: "возможность 4 текст",
        value: "Результаты SEO сперва незначительны, но поток клиентов быстро нарастает.",
    },

    //@Этапы работы

    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Этапы работы",
        group: "seo_worksteps",
        name: "heading",
        label: "заголовок",
        value: "Этапы работы",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Этапы работы",
        group: "seo_worksteps",
        name: "subheading",
        label: "подзаголовок",
        value: "Выберем ключевые запросы для вашего продвижения и конкурентность ниши. Работаем только с “белым” SEO.",
    },

    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Этапы работы",
        group: "seo_worksteps",
        name: "step_1_title",
        label: "заголовок 1",
        value: "БЕСПЛАТНАЯ КОНСУЛЬТАЦИЯ",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Этапы работы",
        group: "seo_worksteps",
        name: "step_1_text",
        label: "текст 1",
        value: "Изучаем вашу нишу и разрабатываем стратегию. Просчитываем сроки продвижения.",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Этапы работы",
        group: "seo_worksteps",
        name: "step_2_title",
        label: "заголовок 2",
        value: "ЧАСТИЧНАЯ ПРЕДОПЛАТА",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Этапы работы",
        group: "seo_worksteps",
        name: "step_2_text",
        label: "текст 2",
        value: "Подписание договора, либо устная договоренность об условиях сдачи и оплаты работы.",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Этапы работы",
        group: "seo_worksteps",
        name: "step_3_title",
        label: "заголовок 3",
        value: "СБОР СЕМАНТИКИ",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Этапы работы",
        group: "seo_worksteps",
        name: "step_3_text",
        label: "текст 3",
        value: "Подбираем ключевые запросы для вашего продвижения, оптимизируем сайт и закупаем “белые” ссылки.",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Этапы работы",
        group: "seo_worksteps",
        name: "step_4_title",
        label: "заголовок 4",
        value: "ЗАПУСК И ПРОГНОЗ",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Этапы работы",
        group: "seo_worksteps",
        name: "step_4_text",
        label: "текст 4",
        value: "Изучаем динамику повышения позиций сайта, корректируем семантику и оптимизацию.",
    },

    //@Цены

    { pageLabel: "SEO", page: "seo", groupLabel: "Цены", group: "seo_prices", name: "heading", label: "заголовок", value: "Цены" },

    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_1_title",
        label: "вариант 1 заголовок",
        value: "LANDING PAGE",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_1_price",
        label: "вариант 1 цена",
        value: "от 20 000 руб.",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_1_option_1",
        label: "вариант 1 возможность 1",
        value: "Анализ конкурентов",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_1_option_2",
        label: "вариант 1 возможность 2",
        value: "Покупка внешних ссылок",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_1_option_3",
        label: "вариант 1 возможность 3",
        value: "Анализ сайта",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_1_option_4",
        label: "вариант 1 возможность 4",
        value: "Подбор ключевых слов",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_1_option_5",
        label: "вариант 1 возможность 5",
        value: "Коррекция текстов сайта",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_1_option_6",
        label: "вариант 1 возможность 6",
        value: "Отчет и прогноз позиций",
    },

    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_2_title",
        label: "вариант 2 заголовок",
        value: "ONLINE МАГАЗИН",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_2_price",
        label: "вариант 2 цена",
        value: "от 40 000 руб.",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_2_option_1",
        label: "вариант 2 возможность 1",
        value: "Анализ конкурентов",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_2_option_2",
        label: "вариант 2 возможность 2",
        value: "Покупка внешних ссылок",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_2_option_3",
        label: "вариант 2 возможность 3",
        value: "Анализ сайта",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_2_option_4",
        label: "вариант 2 возможность 4",
        value: "Подбор ключевых слов",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_2_option_5",
        label: "вариант 2 возможность 5",
        value: "Коррекция текстов сайта",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_2_option_6",
        label: "вариант 2 возможность 6",
        value: "Отчет и прогноз позиций",
    },

    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_3_title",
        label: "вариант 3 заголовок",
        value: "КОРПОРАТИВНЫЙ САЙТ",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_3_price",
        label: "вариант 3 цена",
        value: "от 30 000 руб.",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_3_option_1",
        label: "вариант 3 возможность 1",
        value: "Анализ конкурентов",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_3_option_2",
        label: "вариант 3 возможность 2",
        value: "Покупка внешних ссылок",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_3_option_3",
        label: "вариант 3 возможность 3",
        value: "Анализ сайта",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_3_option_4",
        label: "вариант 3 возможность 4",
        value: "Подбор ключевых слов",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_3_option_5",
        label: "вариант 3 возможность 5",
        value: "Коррекция текстов сайта",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_3_option_6",
        label: "вариант 3 возможность 6",
        value: "Отчет и прогноз позиций",
    },

    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_4_title",
        label: "вариант 4 заголовок",
        value: "КРУПНЫЙ СЕРВИС",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_4_price",
        label: "вариант 4 цена",
        value: "от 50 000 руб.",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_4_option_1",
        label: "вариант 4 возможность 1",
        value: "Анализ конкурентов",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_4_option_2",
        label: "вариант 4 возможность 2",
        value: "Покупка внешних ссылок",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_4_option_3",
        label: "вариант 4 возможность 3",
        value: "Анализ сайта",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_4_option_4",
        label: "вариант 4 возможность 4",
        value: "Подбор ключевых слов",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_4_option_5",
        label: "вариант 4 возможность 5",
        value: "Коррекция текстов сайта",
    },
    {
        pageLabel: "SEO",
        page: "seo",
        groupLabel: "Цены",
        group: "seo_prices",
        name: "variant_4_option_6",
        label: "вариант 4 возможность 6",
        value: "Отчет и прогноз позиций",
    },

    //*@SMM

    //@Хеадер

    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Хеадер",
        group: "smm_header",
        name: "heading_1",
        label: "заголовок 1",
        value: "DIGITAL-агентство полного цикла Cube80",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Хеадер",
        group: "smm_header",
        name: "heading_2",
        label: "заголовок 2",
        value: "Продвижение в соцсетях",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Хеадер",
        group: "smm_header",
        name: "heading_3",
        label: "заголовок 3",
        value: "Приведем клиентов из соцсетей и повысим лояльность к вашей компании.",
    },

    //@Включает

    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Включает",
        group: "smm_includes",
        name: "heading",
        label: "заголовок",
        value: "Что включает",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Включает",
        group: "smm_includes",
        name: "subheading",
        label: "подзаголовок",
        value: "Работа с аудиторией на площадках, где ваши клиенты проводят максимальное количество своего времени.",
    },

    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Включает",
        group: "smm_includes",
        name: "option_1_heading",
        label: "возможность 1 заголовок",
        value: "МАКСИМАЛЬНАЯ ЛОЯЛЬНОСТЬ",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Включает",
        group: "smm_includes",
        name: "option_1_text",
        label: "возможность 1 текст",
        value: "Соцсети вызывают доверие у аудитории и упрощают продажи.",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Включает",
        group: "smm_includes",
        name: "option_2_heading",
        label: "возможность 2 заголовок",
        value: "ЖИВОЕ ОБЩЕНИЕ",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Включает",
        group: "smm_includes",
        name: "option_2_text",
        label: "возможность 2 текст",
        value: "Прямое общение с покупателем увеличивает шанс продажи.",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Включает",
        group: "smm_includes",
        name: "option_3_heading",
        label: "возможность 3 заголовок",
        value: "УЗКИЙ ТАРГЕТИНГ",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Включает",
        group: "smm_includes",
        name: "option_3_text",
        label: "возможность 3 текст",
        value: "Реклама показывается людям, которые уже заинтересованы в покупке.",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Включает",
        group: "smm_includes",
        name: "option_4_heading",
        label: "возможность 4 заголовок",
        value: "НЕЗАВИСИМЫЕ ПРОДАЖИ",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Включает",
        group: "smm_includes",
        name: "option_4_text",
        label: "возможность 4 текст",
        value: "В некоторых соцсетях можно собрать магазин на конструкторе.",
    },

    //@Этапы работы

    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Этапы работы",
        group: "smm_worksteps",
        name: "heading",
        label: "заголовок",
        value: "Этапы работы",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Этапы работы",
        group: "smm_worksteps",
        name: "subheading",
        label: "подзаголовок",
        value: "Подберем соцсети для вашего продвижения и определим точки давления на аудиторию.",
    },

    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Этапы работы",
        group: "smm_worksteps",
        name: "step_1_title",
        label: "заголовок 1",
        value: "БЕСПЛАТНАЯ КОНСУЛЬТАЦИЯ",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Этапы работы",
        group: "smm_worksteps",
        name: "step_1_text",
        label: "текст 1",
        value: "Изучим вашу нишу, после чего определим методику продвижения и бюджеты.",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Этапы работы",
        group: "smm_worksteps",
        name: "step_2_title",
        label: "заголовок 2",
        value: "ЧАСТИЧНАЯ ПРЕДОПЛАТА",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Этапы работы",
        group: "smm_worksteps",
        name: "step_2_text",
        label: "текст 2",
        value: "Подписание договора, либо устная договоренность об условиях сдачи и оплаты работы.",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Этапы работы",
        group: "smm_worksteps",
        name: "step_3_title",
        label: "заголовок 3",
        value: "АНАЛИЗ НИШИ",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Этапы работы",
        group: "smm_worksteps",
        name: "step_3_text",
        label: "текст 3",
        value: "Составление объявлений на основе анализа рынка и конкурентов. Настройка аккаунтов.",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Этапы работы",
        group: "smm_worksteps",
        name: "step_4_title",
        label: "заголовок 4",
        value: "ЗАПУСК И ПРОГНОЗ",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Этапы работы",
        group: "smm_worksteps",
        name: "step_4_text",
        label: "текст 4",
        value: "Изучаем результаты старта кампании и корректируем рекламу. Получаем остаток оплаты.",
    },

    //@Цены

    { pageLabel: "SMM", page: "smm", groupLabel: "Цены", group: "smm_prices", name: "heading", label: "заголовок", value: "Цены" },

    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_1_title",
        label: "вариант 1 заголовок",
        value: "FACEBOOK",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_1_price",
        label: "вариант 1 цена",
        value: "от 20 000 руб.",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_1_option_1",
        label: "вариант 1 возможность 1",
        value: "Изучение ниши",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_1_option_2",
        label: "вариант 1 возможность 2",
        value: "Составление объявлений",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_1_option_3",
        label: "вариант 1 возможность 3",
        value: "Настройка аккаунта",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_1_option_4",
        label: "вариант 1 возможность 4",
        value: "Коррекция ставок рекламы",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_1_option_5",
        label: "вариант 1 возможность 5",
        value: "Ведение аккаунта",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_1_option_6",
        label: "вариант 1 возможность 6",
        value: "Отчет и прогноз продаж",
    },

    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_2_title",
        label: "вариант 2 заголовок",
        value: "VK",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_2_price",
        label: "вариант 2 цена",
        value: "от 20 000 руб.",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_2_option_1",
        label: "вариант 2 возможность 1",
        value: "Изучение ниши",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_2_option_2",
        label: "вариант 2 возможность 2",
        value: "Составление объявлений",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_2_option_3",
        label: "вариант 2 возможность 3",
        value: "Настройка аккаунта",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_2_option_4",
        label: "вариант 2 возможность 4",
        value: "Коррекция ставок рекламы",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_2_option_5",
        label: "вариант 2 возможность 5",
        value: "Ведение аккаунта",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_2_option_6",
        label: "вариант 2 возможность 6",
        value: "Отчет и прогноз продаж",
    },

    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_3_title",
        label: "вариант 3 заголовок",
        value: "INSTAGRAM",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_3_price",
        label: "вариант 3 цена",
        value: "от 25 000 руб.",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_3_option_1",
        label: "вариант 3 возможность 1",
        value: "Изучение ниши",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_3_option_2",
        label: "вариант 3 возможность 2",
        value: "Составление объявлений",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_3_option_3",
        label: "вариант 3 возможность 3",
        value: "Настройка аккаунта",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_3_option_4",
        label: "вариант 3 возможность 4",
        value: "Коррекция ставок рекламы",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_3_option_5",
        label: "вариант 3 возможность 5",
        value: "Ведение аккаунта",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_3_option_6",
        label: "вариант 3 возможность 6",
        value: "Отчет и прогноз продаж",
    },

    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_4_title",
        label: "вариант 4 заголовок",
        value: "TIK TOK",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_4_price",
        label: "вариант 4 цена",
        value: "от 20 000 руб.",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_4_option_1",
        label: "вариант 4 возможность 1",
        value: "Изучение ниши",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_4_option_2",
        label: "вариант 4 возможность 2",
        value: "Составление объявлений",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_4_option_3",
        label: "вариант 4 возможность 3",
        value: "Настройка аккаунта",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_4_option_4",
        label: "вариант 4 возможность 4",
        value: "Коррекция ставок рекламы",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_4_option_5",
        label: "вариант 4 возможность 5",
        value: "Ведение аккаунта",
    },
    {
        pageLabel: "SMM",
        page: "smm",
        groupLabel: "Цены",
        group: "smm_prices",
        name: "variant_4_option_6",
        label: "вариант 4 возможность 6",
        value: "Отчет и прогноз продаж",
    },

    //*@Маркетинг

    //@Хеадер

    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Хеадер",
        group: "marketing_header",
        name: "heading_1",
        label: "заголовок 1",
        value: "DIGITAL-агентство полного цикла Cube80",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Хеадер",
        group: "marketing_header",
        name: "heading_2",
        label: "заголовок 2",
        value: "Брендинг и маркетинг",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Хеадер",
        group: "marketing_header",
        name: "heading_3",
        label: "заголовок 3",
        value: "Разработаем стратегию развития компании, создадим фирменный стиль, либо исправим репутацию.",
    },

    //@Включает

    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Включает",
        group: "marketing_includes",
        name: "heading",
        label: "заголовок",
        value: "Что включает",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Включает",
        group: "marketing_includes",
        name: "subheading",
        label: "подзаголовок",
        value: "SEO оптимизированный сайт приводит клиентов не сразу, однако результат окупает себя в долгосрочной перспективе.",
    },

    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Включает",
        group: "marketing_includes",
        name: "option_1_heading",
        label: "возможность 1 заголовок",
        value: "ФОРМИРОВАНИЕ РЕПУТАЦИИ",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Включает",
        group: "marketing_includes",
        name: "option_1_text",
        label: "возможность 1 текст",
        value: "Исправление негативной репутации, создание положительного образа.",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Включает",
        group: "marketing_includes",
        name: "option_2_heading",
        label: "возможность 2 заголовок",
        value: "БРЕНДИНГ И ФИРМЕННЫЙ СТИЛЬ",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Включает",
        group: "marketing_includes",
        name: "option_2_text",
        label: "возможность 2 текст",
        value: "Формирование корпоративного стиля и имиджа.",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Включает",
        group: "marketing_includes",
        name: "option_3_heading",
        label: "возможность 3 заголовок",
        value: "РАБОТА С ОТЗЫВАМИ",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Включает",
        group: "marketing_includes",
        name: "option_3_text",
        label: "возможность 3 текст",
        value: "Настраивание обратной связи с аудиторией и повышение лояльности.",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Включает",
        group: "marketing_includes",
        name: "option_4_heading",
        label: "возможность 4 заголовок",
        value: "МАРКЕТИНГОВАЯ СТРАТЕГИЯ",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Включает",
        group: "marketing_includes",
        name: "option_4_text",
        label: "возможность 4 текст",
        value: "Глубокий анализ ниши и выбор методики продвижения фирмы.",
    },

    //@Этапы работы

    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Этапы работы",
        group: "marketing_worksteps",
        name: "heading",
        label: "заголовок",
        value: "Этапы работы",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Этапы работы",
        group: "marketing_worksteps",
        name: "subheading",
        label: "подзаголовок",
        value: "Определяем путь вашего развития на ближайшие годы и закладываем точки роста.",
    },

    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Этапы работы",
        group: "marketing_worksteps",
        name: "step_1_title",
        label: "заголовок 1",
        value: "БЕСПЛАТНАЯ КОНСУЛЬТАЦИЯ",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Этапы работы",
        group: "marketing_worksteps",
        name: "step_1_text",
        label: "текст 1",
        value: "Изучим вашу нишу, определим состояние репутации, либо необходимость ребрендинга.",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Этапы работы",
        group: "marketing_worksteps",
        name: "step_2_title",
        label: "заголовок 2",
        value: "ЧАСТИЧНАЯ ПРЕДОПЛАТА",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Этапы работы",
        group: "marketing_worksteps",
        name: "step_2_text",
        label: "текст 2",
        value: "Подписание договора, либо устная договоренность об условиях сдачи и оплаты работы.",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Этапы работы",
        group: "marketing_worksteps",
        name: "step_3_title",
        label: "заголовок 3",
        value: "СОСТАВЛЕНИЕ ПЛАНА РАБОТ",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Этапы работы",
        group: "marketing_worksteps",
        name: "step_3_text",
        label: "текст 3",
        value: "Глубокий анализ рынка и работы по ребрендингу, исправлению репутации, продвижению.",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Этапы работы",
        group: "marketing_worksteps",
        name: "step_4_title",
        label: "заголовок 4",
        value: "СОПРОВОЖДЕНИЕ И ОТЧЕТЫ",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Этапы работы",
        group: "marketing_worksteps",
        name: "step_4_text",
        label: "текст 4",
        value: "Регулярные отчеты о результатах формирования имиджа и бренда компании.",
    },

    //@Цены

    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Цены",
        group: "marketing_prices",
        name: "heading",
        label: "заголовок",
        value: "Цены",
    },

    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Цены",
        group: "marketing_prices",
        name: "variant_1_title",
        label: "вариант 1 заголовок",
        value: "ФИРМЕННЫЙ СТИЛЬ",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Цены",
        group: "marketing_prices",
        name: "variant_1_price",
        label: "вариант 1 цена",
        value: "от 40 000 руб.",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Цены",
        group: "marketing_prices",
        name: "variant_1_option_1",
        label: "вариант 1 возможность 1",
        value: "Создание логотипа",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Цены",
        group: "marketing_prices",
        name: "variant_1_option_2",
        label: "вариант 1 возможность 2",
        value: "Дизайн продукции",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Цены",
        group: "marketing_prices",
        name: "variant_1_option_3",
        label: "вариант 1 возможность 3",
        value: "Корпоративная айдентика",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Цены",
        group: "marketing_prices",
        name: "variant_1_option_4",
        label: "вариант 1 возможность 4",
        value: "Дизайн рекламных носителей",
    },

    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Цены",
        group: "marketing_prices",
        name: "variant_2_title",
        label: "вариант 2 заголовок",
        value: "ИСПРАВЛЕНИЕ РЕПУТАЦИИ",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Цены",
        group: "marketing_prices",
        name: "variant_2_price",
        label: "вариант 2 цена",
        value: "от 60 000 руб.",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Цены",
        group: "marketing_prices",
        name: "variant_2_option_1",
        label: "вариант 2 возможность 1",
        value: "Работа с отзывами",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Цены",
        group: "marketing_prices",
        name: "variant_2_option_2",
        label: "вариант 2 возможность 2",
        value: "Работа со СМИ",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Цены",
        group: "marketing_prices",
        name: "variant_2_option_3",
        label: "вариант 2 возможность 3",
        value: "Внешнее SEO",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Цены",
        group: "marketing_prices",
        name: "variant_2_option_4",
        label: "вариант 2 возможность 4",
        value: "Коррекция сайта",
    },

    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Цены",
        group: "marketing_prices",
        name: "variant_3_title",
        label: "вариант 3 заголовок",
        value: "МАРКЕТИНГ ПОД КЛЮЧ",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Цены",
        group: "marketing_prices",
        name: "variant_3_price",
        label: "вариант 3 цена",
        value: "от 120 000 руб.",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Цены",
        group: "marketing_prices",
        name: "variant_3_option_1",
        label: "вариант 3 возможность 1",
        value: "Глубокий анализ ниши",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Цены",
        group: "marketing_prices",
        name: "variant_3_option_2",
        label: "вариант 3 возможность 2",
        value: "Брендинг",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Цены",
        group: "marketing_prices",
        name: "variant_3_option_3",
        label: "вариант 3 возможность 3",
        value: "Составление точек роста",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Цены",
        group: "marketing_prices",
        name: "variant_3_option_4",
        label: "вариант 3 возможность 4",
        value: "Работа с репутацией",
    },

    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Цены",
        group: "marketing_prices",
        name: "variant_4_title",
        label: "вариант 4 заголовок",
        value: "МАРКЕТИНГ СОЦСЕТЕЙ",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Цены",
        group: "marketing_prices",
        name: "variant_4_price",
        label: "вариант 4 цена",
        value: "от 40 000 руб.",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Цены",
        group: "marketing_prices",
        name: "variant_4_option_1",
        label: "вариант 4 возможность 1",
        value: "Ведение аккаунтов",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Цены",
        group: "marketing_prices",
        name: "variant_4_option_2",
        label: "вариант 4 возможность 2",
        value: "Отрисовка дизайна",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Цены",
        group: "marketing_prices",
        name: "variant_4_option_3",
        label: "вариант 4 возможность 3",
        value: "Работа с обратной связью",
    },
    {
        pageLabel: "Маркетинг",
        page: "marketing",
        groupLabel: "Цены",
        group: "marketing_prices",
        name: "variant_4_option_4",
        label: "вариант 4 возможность 4",
        value: "Рекламные кампании",
    },

    //*@Приложения

    //@Хеадер

    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Хеадер",
        group: "apps_header",
        name: "heading_1",
        label: "заголовок 1",
        value: "DIGITAL-агентство полного цикла Cube80",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Хеадер",
        group: "apps_header",
        name: "heading_2",
        label: "заголовок 2",
        value: "Разработка приложений",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Хеадер",
        group: "apps_header",
        name: "heading_3",
        label: "заголовок 3",
        value: "Создание приложений под IOS, Android, дублирование функционала сайта.",
    },

    //@Включает

    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Включает",
        group: "apps_includes",
        name: "heading",
        label: "заголовок",
        value: "Что включает",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Включает",
        group: "apps_includes",
        name: "subheading",
        label: "подзаголовок",
        value: "Разработка концепции приложения и его внедрение на рынок, гарантийный период тестирования для выявления ошибок.",
    },

    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Включает",
        group: "apps_includes",
        name: "option_1_heading",
        label: "возможность 1 заголовок",
        value: "УВЕДОМЛЕНИЯ И СБОР ДАННЫХ",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Включает",
        group: "apps_includes",
        name: "option_1_text",
        label: "возможность 1 текст",
        value: "Пользователи оперативно получают уведомления от вашего сервиса, а вы собираете базу клиентов.",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Включает",
        group: "apps_includes",
        name: "option_2_heading",
        label: "возможность 2 заголовок",
        value: "КОНВЕРСИЯ ДО 300%",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Включает",
        group: "apps_includes",
        name: "option_2_text",
        label: "возможность 2 текст",
        value: "Приложения приносят в несколько раз больше клиентов за счет расширенного функционала.",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Включает",
        group: "apps_includes",
        name: "option_3_heading",
        label: "возможность 3 заголовок",
        value: "ПОПУЛЯРНЕЕ САЙТА",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Включает",
        group: "apps_includes",
        name: "option_3_text",
        label: "возможность 3 текст",
        value: "Постоянные пользователи предпочитают использовать приложение чаще, чем сайт.",
    },

    //@Этапы работы

    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Этапы работы",
        group: "apps_worksteps",
        name: "heading",
        label: "заголовок",
        value: "Этапы работы",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Этапы работы",
        group: "apps_worksteps",
        name: "subheading",
        label: "подзаголовок",
        value: "Совместно с вами определим задачи, которые должно решать приложение. Разработаем удобную для пользователя структуру и интерфейс.",
    },

    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Этапы работы",
        group: "apps_worksteps",
        name: "step_1_title",
        label: "заголовок 1",
        value: "БЕСПЛАТНАЯ КОНСУЛЬТАЦИЯ",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Этапы работы",
        group: "apps_worksteps",
        name: "step_1_text",
        label: "текст 1",
        value: "Выясняем требования к приложению и выбираем стэк технологий для разработки.",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Этапы работы",
        group: "apps_worksteps",
        name: "step_2_title",
        label: "заголовок 2",
        value: "ЧАСТИЧНАЯ ПРЕДОПЛАТА",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Этапы работы",
        group: "apps_worksteps",
        name: "step_2_text",
        label: "текст 2",
        value: "Подписание договора, либо устная договоренность об условиях сдачи и оплаты работы.",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Этапы работы",
        group: "apps_worksteps",
        name: "step_3_title",
        label: "заголовок 3",
        value: "РАЗРАБОТКА ПРИЛОЖЕНИЯ",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Этапы работы",
        group: "apps_worksteps",
        name: "step_3_text",
        label: "текст 3",
        value: "Написание frontend и backend части приложения. Поэтапное утверждение и оплата работы.",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Этапы работы",
        group: "apps_worksteps",
        name: "step_4_title",
        label: "заголовок 4",
        value: "ДОРАБОТКА, ТЕСТЫ, ЗАПУСК",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Этапы работы",
        group: "apps_worksteps",
        name: "step_4_text",
        label: "текст 4",
        value: "Тестирование функционала, запуск, внесение остатка оплаты и обсуждение поддержки.",
    },

    //@Цены

    { pageLabel: "Приложения", page: "apps", groupLabel: "Цены", group: "apps_prices", name: "heading", label: "заголовок", value: "Цены" },

    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Цены",
        group: "apps_prices",
        name: "variant_1_title",
        label: "вариант 1 заголовок",
        value: "ANDROID ПРИЛОЖЕНИЯ",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Цены",
        group: "apps_prices",
        name: "variant_1_price",
        label: "вариант 1 цена",
        value: "от 400 000 руб.",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Цены",
        group: "apps_prices",
        name: "variant_1_option_1",
        label: "вариант 1 возможность 1",
        value: "Гарантия 3 месяца",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Цены",
        group: "apps_prices",
        name: "variant_1_option_2",
        label: "вариант 1 возможность 2",
        value: "Поддержка и доработка",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Цены",
        group: "apps_prices",
        name: "variant_1_option_3",
        label: "вариант 1 возможность 3",
        value: "Бесплатная правка багов",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Цены",
        group: "apps_prices",
        name: "variant_1_option_4",
        label: "вариант 1 возможность 4",
        value: "Загрузка в Google play",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Цены",
        group: "apps_prices",
        name: "variant_1_option_5",
        label: "вариант 1 возможность 5",
        value: "Аналитика рынка",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Цены",
        group: "apps_prices",
        name: "variant_1_option_6",
        label: "вариант 1 возможность 6",
        value: "Межплатформенная интеграция",
    },

    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Цены",
        group: "apps_prices",
        name: "variant_2_title",
        label: "вариант 2 заголовок",
        value: "IOS ПРИЛОЖЕНИЯ",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Цены",
        group: "apps_prices",
        name: "variant_2_price",
        label: "вариант 2 цена",
        value: "от 450 000 руб.",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Цены",
        group: "apps_prices",
        name: "variant_2_option_1",
        label: "вариант 2 возможность 1",
        value: "Гарантия 3 месяца",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Цены",
        group: "apps_prices",
        name: "variant_2_option_2",
        label: "вариант 2 возможность 2",
        value: "Поддержка и доработка",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Цены",
        group: "apps_prices",
        name: "variant_2_option_3",
        label: "вариант 2 возможность 3",
        value: "Бесплатная правка багов",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Цены",
        group: "apps_prices",
        name: "variant_2_option_4",
        label: "вариант 2 возможность 4",
        value: "Загрузка в App Store",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Цены",
        group: "apps_prices",
        name: "variant_2_option_5",
        label: "вариант 2 возможность 5",
        value: "Аналитика рынка",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Цены",
        group: "apps_prices",
        name: "variant_2_option_6",
        label: "вариант 2 возможность 6",
        value: "Межплатформенная интеграция",
    },

    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Цены",
        group: "apps_prices",
        name: "variant_3_title",
        label: "вариант 3 заголовок",
        value: "WINDOWS ПРИЛОЖЕНИЯ",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Цены",
        group: "apps_prices",
        name: "variant_3_price",
        label: "вариант 3 цена",
        value: "от 400 000 руб.",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Цены",
        group: "apps_prices",
        name: "variant_3_option_1",
        label: "вариант 3 возможность 1",
        value: "Гарантия 3 месяца",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Цены",
        group: "apps_prices",
        name: "variant_3_option_2",
        label: "вариант 3 возможность 2",
        value: "Поддержка и доработка",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Цены",
        group: "apps_prices",
        name: "variant_3_option_3",
        label: "вариант 3 возможность 3",
        value: "Бесплатная правка багов",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Цены",
        group: "apps_prices",
        name: "variant_3_option_4",
        label: "вариант 3 возможность 4",
        value: "Загрузка приложения",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Цены",
        group: "apps_prices",
        name: "variant_3_option_5",
        label: "вариант 3 возможность 5",
        value: "Аналитика рынка",
    },
    {
        pageLabel: "Приложения",
        page: "apps",
        groupLabel: "Цены",
        group: "apps_prices",
        name: "variant_3_option_6",
        label: "вариант 3 возможность 6",
        value: "Межплатформенная интеграция",
    },

    //*@Самописные системы

    //@Хеадер

    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Хеадер",
        group: "system_header",
        name: "heading_1",
        label: "заголовок 1",
        value: "DIGITAL-агентство полного цикла Cube80",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Хеадер",
        group: "system_header",
        name: "heading_2",
        label: "заголовок 2",
        value: "Самописные системы",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Хеадер",
        group: "system_header",
        name: "heading_3",
        label: "заголовок 3",
        value: "Разработаем систему управления, либо сервис под ваши нестандартные задачи.",
    },

    //@Включает

    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Включает",
        group: "system_includes",
        name: "heading",
        label: "заголовок",
        value: "Что включает",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Включает",
        group: "system_includes",
        name: "subheading",
        label: "подзаголовок",
        value: "Если ни одна из имеющихся систем управления не удовлетворяет вашим требованиям, то оптимальным решением станет написание своей.",
    },

    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Включает",
        group: "system_includes",
        name: "option_1_heading",
        label: "возможность 1 заголовок",
        value: "СКОРОСТЬ РАБОТЫ",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Включает",
        group: "system_includes",
        name: "option_1_text",
        label: "возможность 1 текст",
        value: "Сайт работает гораздо быстрее за счет отсутствия ненужного функционала.",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Включает",
        group: "system_includes",
        name: "option_2_heading",
        label: "возможность 2 заголовок",
        value: "МАСШТАБИРУЕМОСТЬ",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Включает",
        group: "system_includes",
        name: "option_2_text",
        label: "возможность 2 текст",
        value: "Легко добавлять новые модули и дорабатывать имеющийся функционал.",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Включает",
        group: "system_includes",
        name: "option_3_heading",
        label: "возможность 3 заголовок",
        value: "НЕТ ОГРАНИЧЕНИЙ",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Включает",
        group: "system_includes",
        name: "option_3_text",
        label: "возможность 3 текст",
        value: "Стандартные системы управления ограничены своим функционалом. Самописные ограничений не имеют.",
    },

    //@Этапы работы

    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Этапы работы",
        group: "system_worksteps",
        name: "heading",
        label: "заголовок",
        value: "Этапы работы",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Этапы работы",
        group: "system_worksteps",
        name: "subheading",
        label: "подзаголовок",
        value: "Определим перечень функционала, который необходимо реализовать. Выберем язык программирования и фреймворк для разработки системы управления.",
    },

    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Этапы работы",
        group: "system_worksteps",
        name: "step_1_title",
        label: "заголовок 1",
        value: "БЕСПЛАТНАЯ КОНСУЛЬТАЦИЯ",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Этапы работы",
        group: "system_worksteps",
        name: "step_1_text",
        label: "текст 1",
        value: "Выясняем требования к вашей системе и выбираем стэк технологий для разработки.",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Этапы работы",
        group: "system_worksteps",
        name: "step_2_title",
        label: "заголовок 2",
        value: "ЧАСТИЧНАЯ ПРЕДОПЛАТА",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Этапы работы",
        group: "system_worksteps",
        name: "step_2_text",
        label: "текст 2",
        value: "Подписание договора, либо устная договоренность об условиях сдачи и оплаты работы.",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Этапы работы",
        group: "system_worksteps",
        name: "step_3_title",
        label: "заголовок 3",
        value: "РАЗРАБОТКА",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Этапы работы",
        group: "system_worksteps",
        name: "step_3_text",
        label: "текст 3",
        value: "Написание frontend и backend части системы. Поэтапное утверждение и оплата работы.",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Этапы работы",
        group: "system_worksteps",
        name: "step_4_title",
        label: "заголовок 4",
        value: "ТЕСТЫ И ЗАПУСК",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Этапы работы",
        group: "system_worksteps",
        name: "step_4_text",
        label: "текст 4",
        value: "Тестирование функционала, сдача работы и внесение остатка оплаты.",
    },

    //@Цены

    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Цены",
        group: "system_prices",
        name: "heading",
        label: "заголовок",
        value: "Цены",
    },

    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Цены",
        group: "system_prices",
        name: "variant_1_title",
        label: "вариант 1 заголовок",
        value: "СИСТЕМЫ УПРАВЛЕНИЯ",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Цены",
        group: "system_prices",
        name: "variant_1_price",
        label: "вариант 1 цена",
        value: "от 250 000 руб.",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Цены",
        group: "system_prices",
        name: "variant_1_option_1",
        label: "вариант 1 возможность 1",
        value: "Автоматические продажи",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Цены",
        group: "system_prices",
        name: "variant_1_option_2",
        label: "вариант 1 возможность 2",
        value: "Онлайн-калькуляторы",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Цены",
        group: "system_prices",
        name: "variant_1_option_3",
        label: "вариант 1 возможность 3",
        value: "Базы данных продаж",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Цены",
        group: "system_prices",
        name: "variant_1_option_4",
        label: "вариант 1 возможность 4",
        value: "Мульти-администрирование",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Цены",
        group: "system_prices",
        name: "variant_1_option_5",
        label: "вариант 1 возможность 5",
        value: "Онлайн оплата",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Цены",
        group: "system_prices",
        name: "variant_1_option_6",
        label: "вариант 1 возможность 6",
        value: "Встроенная аналитика",
    },

    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Цены",
        group: "system_prices",
        name: "variant_2_title",
        label: "вариант 2 заголовок",
        value: "КРУПНЫЕ СЕРВИСЫ",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Цены",
        group: "system_prices",
        name: "variant_2_price",
        label: "вариант 2 цена",
        value: "от 350 000 руб.",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Цены",
        group: "system_prices",
        name: "variant_2_option_1",
        label: "вариант 2 возможность 1",
        value: "Системы бух. учета",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Цены",
        group: "system_prices",
        name: "variant_2_option_2",
        label: "вариант 2 возможность 2",
        value: "Маркетплейсы",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Цены",
        group: "system_prices",
        name: "variant_2_option_3",
        label: "вариант 2 возможность 3",
        value: "Торговые биржи",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Цены",
        group: "system_prices",
        name: "variant_2_option_4",
        label: "вариант 2 возможность 4",
        value: "Системы ведения проектов",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Цены",
        group: "system_prices",
        name: "variant_2_option_5",
        label: "вариант 2 возможность 5",
        value: "Фриланс биржи",
    },
    {
        pageLabel: "Самописные системы",
        page: "system",
        groupLabel: "Цены",
        group: "system_prices",
        name: "variant_2_option_6",
        label: "вариант 2 возможность 6",
        value: "Системы управления персоналом",
    },

    //*@Парсинг

    //@Хеадер

    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Хеадер",
        group: "parsing_header",
        name: "heading_1",
        label: "заголовок 1",
        value: "DIGITAL-агентство полного цикла Cube80",
    },
    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Хеадер",
        group: "parsing_header",
        name: "heading_2",
        label: "заголовок 2",
        value: "Написание парсеров и наполнение",
    },
    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Хеадер",
        group: "parsing_header",
        name: "heading_3",
        label: "заголовок 3",
        value: "Спарсим товары на ваш online магазин, подключим API торговых площадок.",
    },

    //@Включает

    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Включает",
        group: "parsing_includes",
        name: "heading",
        label: "заголовок",
        value: "Что включает",
    },
    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Включает",
        group: "parsing_includes",
        name: "subheading",
        label: "подзаголовок",
        value: "Автоматизация наполнения сайта без участия ручного труда. Синхронизация с базой данных.",
    },

    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Включает",
        group: "parsing_includes",
        name: "option_1_heading",
        label: "возможность 1 заголовок",
        value: "АВТОМАТИЧЕСКОЕ НАПОЛНЕНИЕ",
    },
    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Включает",
        group: "parsing_includes",
        name: "option_1_text",
        label: "возможность 1 текст",
        value: "Не надо следить за сайтом-донором. Обновление каталога автоматическое.",
    },
    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Включает",
        group: "parsing_includes",
        name: "option_2_heading",
        label: "возможность 2 заголовок",
        value: "ЭКОНОМИЯ НА СОТРУДНИКАХ",
    },
    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Включает",
        group: "parsing_includes",
        name: "option_2_text",
        label: "возможность 2 текст",
        value: "Больше не нужно платить зарплату человеку, который наполнял сайт.",
    },
    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Включает",
        group: "parsing_includes",
        name: "option_3_heading",
        label: "возможность 3 заголовок",
        value: "СИНХРОНИЗАЦИЯ С БУХГАЛТЕРИЕЙ",
    },
    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Включает",
        group: "parsing_includes",
        name: "option_3_text",
        label: "возможность 3 текст",
        value: "Вся информация о продажах поступает в вашу бухгалтерию автоматически.",
    },

    //@Этапы работы

    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Этапы работы",
        group: "parsing_worksteps",
        name: "heading",
        label: "заголовок",
        value: "Этапы работы",
    },
    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Этапы работы",
        group: "parsing_worksteps",
        name: "subheading",
        label: "подзаголовок",
        value: "Выберем язык программирования для разработки парсера. Определяем требования API.",
    },

    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Этапы работы",
        group: "parsing_worksteps",
        name: "step_1_title",
        label: "заголовок 1",
        value: "БЕСПЛАТНАЯ КОНСУЛЬТАЦИЯ",
    },
    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Этапы работы",
        group: "parsing_worksteps",
        name: "step_1_text",
        label: "текст 1",
        value: "Обсуждаем требования к вашему парсеру, либо изучаем технологию подключаемого API.",
    },
    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Этапы работы",
        group: "parsing_worksteps",
        name: "step_2_title",
        label: "заголовок 2",
        value: "ЧАСТИЧНАЯ ПРЕДОПЛАТА",
    },
    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Этапы работы",
        group: "parsing_worksteps",
        name: "step_2_text",
        label: "текст 2",
        value: "Подписание договора, либо устная договоренность об условиях сдачи и оплаты работы.",
    },
    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Этапы работы",
        group: "parsing_worksteps",
        name: "step_3_title",
        label: "заголовок 3",
        value: "РАЗРАБОТКА",
    },
    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Этапы работы",
        group: "parsing_worksteps",
        name: "step_3_text",
        label: "текст 3",
        value: "Написание frontend и backend части парсера. Поэтапное утверждение и оплата работы.",
    },
    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Этапы работы",
        group: "parsing_worksteps",
        name: "step_4_title",
        label: "заголовок 4",
        value: "ТЕСТЫ И ЗАПУСК",
    },
    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Этапы работы",
        group: "parsing_worksteps",
        name: "step_4_text",
        label: "текст 4",
        value: "Тестирование функционала системы парсинга, сдача работы и внесение остатка оплаты.",
    },

    //@Цены

    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Цены",
        group: "parsing_prices",
        name: "heading",
        label: "заголовок",
        value: "Цены",
    },

    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Цены",
        group: "parsing_prices",
        name: "variant_1_title",
        label: "вариант 1 заголовок",
        value: "СИСТЕМЫ УПРАВЛЕНИЯ",
    },
    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Цены",
        group: "parsing_prices",
        name: "variant_1_price",
        label: "вариант 1 цена",
        value: "от 60 000 руб.",
    },
    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Цены",
        group: "parsing_prices",
        name: "variant_1_option_1",
        label: "вариант 1 возможность 1",
        value: "Подгрузка товаров на сайт",
    },
    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Цены",
        group: "parsing_prices",
        name: "variant_1_option_2",
        label: "вариант 1 возможность 2",
        value: "Синхронизация бухгалтерии",
    },
    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Цены",
        group: "parsing_prices",
        name: "variant_1_option_3",
        label: "вариант 1 возможность 3",
        value: "Копирование цен",
    },
    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Цены",
        group: "parsing_prices",
        name: "variant_1_option_4",
        label: "вариант 1 возможность 4",
        value: "Круглосуточный апдейт",
    },

    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Цены",
        group: "parsing_prices",
        name: "variant_2_title",
        label: "вариант 2 заголовок",
        value: "КРУПНЫЕ СЕРВИСЫ",
    },
    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Цены",
        group: "parsing_prices",
        name: "variant_2_price",
        label: "вариант 2 цена",
        value: "от 30 000 руб.",
    },
    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Цены",
        group: "parsing_prices",
        name: "variant_2_option_1",
        label: "вариант 2 возможность 1",
        value: "Подгрузка товаров на сайт",
    },
    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Цены",
        group: "parsing_prices",
        name: "variant_2_option_2",
        label: "вариант 2 возможность 2",
        value: "Синхронизация бухгалтерии",
    },
    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Цены",
        group: "parsing_prices",
        name: "variant_2_option_3",
        label: "вариант 2 возможность 3",
        value: "Копирование цен",
    },
    {
        pageLabel: "Парсинг",
        page: "parsing",
        groupLabel: "Цены",
        group: "parsing_prices",
        name: "variant_2_option_4",
        label: "вариант 2 возможность 4",
        value: "Круглосуточный апдейт",
    },
];
const casesFallback = [
    {
        group: "landing",
        value: "Служба сантехников",
        ImageBig: case2,
        ImageSmall: mini2,
    },
    {
        group: "landing",
        value: "Логистическая компания",
        ImageBig: case7,
        ImageSmall: mini7,
    },
    {
        group: "landing",
        value: "Студия детской гимнастики",
        ImageBig: case3,
        ImageSmall: mini3,
    },
    {
        group: "landing",
        value: "Биржа торговли Blockchain",
        ImageBig: case10,
        ImageSmall: mini10,
    },
    {
        group: "landing",
        value: "Сервис взаимопомощи",
        ImageBig: case12,
        ImageSmall: mini12,
    },
    {
        group: "landing",
        value: "Биржа найма поваров",
        ImageBig: case13,
        ImageSmall: mini13,
    },
    {
        group: "landing",
        value: "Криптовалютный обменник",
        ImageBig: case14,
        ImageSmall: mini14,
    },
    {
        group: "landing",
        value: "Рекрутинговая IT компания",
        ImageBig: case16,
        ImageSmall: mini16,
    },

    {
        group: "landing",
        value: "Клининговая компания",
        ImageBig: case19,
        ImageSmall: mini19,
    },
    {
        group: "landing",
        value: "Сервис автоподбора",
        ImageBig: case20,
        ImageSmall: mini20,
    },
    {
        group: "landing",
        value: "Спортивный магазин",
        ImageBig: case21,
        ImageSmall: mini21,
    },
    {
        group: "landing",
        value: "Продажа земли на Бали",
        ImageBig: case22,
        ImageSmall: mini22,
    },
    //shops
    {
        group: "shop",
        value: "Ювелирный магазин",
        ImageSmall: mini18,
        ImageBig: case18,
    },
    {
        group: "shop",
        value: "Фабрика мебели",
        ImageBig: case9,
        ImageSmall: mini9,
    },
    {
        group: "shop",
        value: "Магазин женской одежды",
        ImageBig: case8,
        ImageSmall: mini8,
    },
    {
        group: "shop",
        value: "Бургер-бар",
        ImageBig: case4,
        ImageSmall: mini4,
    },
    {
        group: "shop",
        value: "Спортивный магазин",
        ImageBig: case21,
        ImageSmall: case21,
    },
    {
        group: "shop",
        value: "Вейп-шоп",
        ImageBig: case23,
        ImageSmall: mini23,
    },
    //multi
    {
        group: "multi",
        value: "Файлообменник",
        ImageSmall: mini17,
        ImageBig: case17,
    },
    {
        group: "multi",
        value: "Сервис одноразовых записок",
        ImageSmall: mini11,
        ImageBig: case11,
    },
    {
        group: "multi",
        value: "Юридическая компания",
        ImageSmall: mini5,
        ImageBig: case5,
    },
    {
        group: "multi",
        value: "Фриланс-площадка косметологов",
        ImageSmall: mini6,
        ImageBig: case6,
    },
    {
        group: "multi",
        value: "Обменник криптовалют",
        ImageSmall: mini1,
        ImageBig: case1,
    },
    {
        group: "multi",
        value: "Служба мастеров на час",
        ImageSmall: mini15,
        ImageBig: case15,
    },
    {
        group: "multi",
        value: "Биржа найма поваров",
        ImageSmall: mini13,
        ImageBig: case13,
    },
    {
        group: "multi",
        value: "Сервис продажи SSL-сертификатов",
        ImageSmall: mini24,
        ImageBig: case24,
    },
    {
        group: "multi",
        value: "Сервис автоподбора",
        ImageSmall: mini20,
        ImageBig: case20,
    },
    {
        group: "multi",
        value: "Клининговая компания",
        ImageSmall: mini19,
        ImageBig: case19,
    },
];
const workersFallback = [
    {
        name: "Николай Борисов",
        profession: "менеджер проектов",
        image: nikolai,
    },
    {
        name: "Наталия Чурилова",
        profession: "маркетолог-партнер",
        image: natalya,
    },
    {
        name: "Даниил Фуштей",
        profession: "web-дизайнер",
        image: daniil,
    },
    {
        name: "Наша вакансия",
        profession: "Python разработчик",
        image: developer,
    },
    {
        name: "Максим Коноваленко",
        profession: "frontend разработчик",
        image: maxim,
    },
    {
        name: "Егор Яцук",
        profession: "backend разработчик",
        image: egor,
    },
];
const contactsFallback = [
    { id: 1, name: "telegram", value: "https://t.me/ozzodesign" },
    { id: 2, name: "vk", value: "https://vk.com/ozzo_web_design" },
    { id: 3, name: "whatsapp", value: "https://api.whatsapp.com/send/?phone=79811036538&text&type=phone_number&app_absent=0" },
];

const convertTexts = (texts) => {
    const formatedTexts = {};
    texts.forEach((text) => {
        if (!formatedTexts[text.group]) {
            formatedTexts[text.group] = {};
        }
        formatedTexts[text.group] = { ...formatedTexts[text.group], [text.name]: text };
    });
    return formatedTexts;
};

const convertCases = (cases) => {
    const formatedCases = {};

    cases.forEach((caseItem) => {
        if (!formatedCases[caseItem.group]) {
            formatedCases[caseItem.group] = [];
        }
        formatedCases[caseItem.group] = [...formatedCases[caseItem.group], caseItem];
    });

    return formatedCases;
};

const ContentContext = createContext(undefined);

export const ContentProvider = (props) => {
    const [texts, setTexts] = useState(undefined);
    const [cases, setCases] = useState(undefined);
    const [workers, setWorkers] = useState(undefined);
    const [contacts, setContacts] = useState(undefined);

    const fetchTexts = useCallback(async () => {
        try {
            const res = await fetch(`${HOST}/texts`, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (!res.ok) {
                return setTexts(convertTexts(textsFallback));
            }

            const data = await res.json();

            setTexts(convertTexts(data));
        } catch {
            return setTexts(convertTexts(textsFallback));
        }
    }, []);
    const fetchCases = useCallback(async () => {
        try {
            const res = await fetch(`${HOST}/cases`, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (!res.ok) {
                return setCases(convertCases(casesFallback));
            }

            const data = await res.json();

            setCases(convertCases(data));
        } catch {
            return setCases(convertCases(casesFallback));
        }
    }, []);
    const fetchWorkers = useCallback(async () => {
        try {
            const res = await fetch(`${HOST}/workers`, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (!res.ok) {
                return setWorkers(workersFallback);
            }

            const data = await res.json();
            setWorkers(data);
        } catch {
            return setWorkers(workersFallback);
        }
    }, []);
    const fetchContacts = useCallback(async () => {
        try {
            const res = await fetch(`${HOST}/contacts`, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (!res.ok) {
                return setContacts(contactsFallback);
            }

            const data = await res.json();
            setContacts(data);
        } catch {
            return setContacts(contactsFallback);
        }
    }, []);

    useEffect(() => {
        fetchTexts();
        fetchCases();
        fetchWorkers();
        fetchContacts();
    }, [fetchTexts, fetchCases, fetchWorkers, fetchContacts]);

    const getTextGroup = (group) => {
        if (typeof texts !== "undefined") {
            const groupItem = texts[group];
            if (groupItem) return groupItem;
        }
        const groupItemFallback = textsFallback[group];
        if (groupItemFallback) return groupItemFallback;
        return null;
    };

    const getText = (group, name) => {
        if (typeof texts !== "undefined") {
            const groupItem = texts[group];
            if (groupItem) {
                const item = groupItem[name];
                if (item) return item;
            }
        }
        const groupItemFallback = textsFallback[group];
        if (groupItemFallback) {
            const item = groupItemFallback[name];
            if (item) return item;
        }
        return "";
    };

    const getCaseGroup = (group) => {
        if (typeof cases !== "undefined") {
            const groupItem = cases[group];
            if (groupItem) return groupItem;
        }
        const groupItemFallback = casesFallback[group];
        if (groupItemFallback) return groupItemFallback;
        return null;
    };

    const getWorkers = () => {
        if (typeof workers !== "undefined") {
            return workers;
        }
        return workersFallback;
    };
    const getContacts = () => {
        if (typeof contacts !== "undefined") {
            return contacts;
        }
        return contactsFallback;
    };
    return (
        <ContentContext.Provider
            value={{
                texts,
                fetchTexts,
                getText,
                getTextGroup,
                cases,
                fetchCases,
                getCaseGroup,
                getContacts,
                fetchContacts,
                workers,
                fetchWorkers,
                getWorkers,
            }}
        >
            {typeof texts !== "undefined" &&
            texts !== null &&
            typeof cases !== "undefined" &&
            cases !== null &&
            typeof workers !== "undefined" &&
            workers !== null &&
            typeof contacts !== "undefined" &&
            contacts !== null
                ? props.children
                : ``}
        </ContentContext.Provider>
    );
};

export const useContentContext = () => {
    const contentContext = useContext(ContentContext);
    if (!contentContext) {
        throw new Error("No ContentContext.Provider found when calling useContentContext");
    }
    return contentContext;
};
