import cn from "classnames";
import React from "react";
import { Element } from "react-scroll";
import columns from "../../../images/systemsPage/columns.svg";
import noLimits from "../../../images/systemsPage/noLimits.svg";
import noLimitsTablet from "../../../images/systemsPage/noLimitsTablet.svg";
import speed from "../../../images/systemsPage/speed.svg";
import { useContentContext } from "../../../providers/Content.provider";
import styles from "./Includes.module.css";

const Includes = () => {
    const { getTextGroup } = useContentContext();

    const textGroup = getTextGroup("system_includes");
    return (
        <section className={styles.includes}>
            <Element name="systems-second">
                <div className="container">
                    <div className={styles.wrapper}>
                        <h2 className={styles.title}>{textGroup.heading.value}</h2>
                        <p className={styles.descr}>{textGroup.subheading.value}</p>
                        <div className={styles.gridWrapper}>
                            <div className={cn(styles.gridItem, styles.white)}>
                                <h3 className={cn(styles.subtitle, styles.subtitleBlack)}>{textGroup.option_1_heading.value}</h3>
                                <p className={cn(styles.gridDescr, styles.gridDescrLong, styles.gridDescrBlack)}>
                                    {textGroup.option_1_text.value}
                                </p>
                                <picture>
                                    <source srcSet={noLimits} media="(max-width: 725px)" />
                                    <source srcSet={noLimitsTablet} media="(max-width: 991px)" />
                                    <img src={noLimits} alt="noLimits" className={styles.noLimits} />
                                </picture>
                            </div>
                            <div className={cn(styles.gridItem, styles.white)}>
                                <h3 className={cn(styles.subtitle, styles.subtitleBlack)}>{textGroup.option_2_heading.value}</h3>
                                <p className={cn(styles.gridDescr, styles.gridDescrShort, styles.gridDescrBlack)}>
                                    {textGroup.option_2_text.value}
                                </p>
                                <img src={speed} alt="speed" className={styles.speed} />
                            </div>
                            <div className={cn(styles.gridItem, styles.blue)}>
                                <h3 className={cn(styles.subtitle, styles.subtitleWhite)}>{textGroup.option_3_heading.value}</h3>
                                <p className={cn(styles.gridDescr, styles.gridDescrWhite, styles.gridDescrMiddle)}>
                                    {textGroup.option_3_text.value}
                                </p>
                                <img src={columns} alt="columns" className={styles.columns} />
                            </div>
                        </div>
                    </div>
                </div>
            </Element>
        </section>
    );
};

export default Includes;
