import React from "react";
import Button from "../../../elements/Button";
import { SmmAnimation } from "../../../icons/components/SmmAnimation/SmmAnimation";
import { useContentContext } from "../../../providers/Content.provider";
import { scrollToElement } from "../../../utils/scrollToElement";
import styles from "./Main.module.css";

const Main = () => {
    const { getTextGroup } = useContentContext();

    const textGroup = getTextGroup("smm_header");
    return (
        <section className={styles.main}>
            <div className={styles.scroll} onClick={() => scrollToElement("smm-second")}>
                <div className={styles.scrollOutside}></div>
                <div className={styles.scrollInner}>
                    <div className={styles.scrollCircle}></div>
                </div>
                <p className={styles.scrollText}>scroll</p>
            </div>
            <div className="container">
                <div className={styles.wrapper}>
                    <div className={styles.left}>
                        <h1 className={styles.title}>{textGroup.heading_1.value}</h1>
                        <h2 className={styles.subtitle}>{textGroup.heading_2.value}</h2>
                        <p className={styles.descr}>{textGroup.heading_3.value}</p>
                        <div className={styles.btnWrapper}>
                            <Button
                                btnColor="#000000"
                                btnStyles={[
                                    { background: "#1ECD44", color: "#fff", border: "none" },
                                    { background: "#fff", color: "#1a1a1a", border: "1px solid #1a1a1a" },
                                ]}
                            />
                        </div>
                    </div>
                    <div className={styles.right}>
                        <SmmAnimation className={styles.image} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Main;
