import AOS from "aos";
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.scss";
import ApplicationsPage from "./pages/ApplicationsPage";
import ContextPage from "./pages/ContextPage";
import Layout from "./pages/Layout";
import MainPage from "./pages/MainPage";
import MarketingPage from "./pages/MarketingPage";
import ParserPage from "./pages/ParserPage";
import PolicyPage from "./pages/PolicyPage";
import SeoPage from "./pages/SeoPage";
import SmmPage from "./pages/SmmPage";
import SystemsPage from "./pages/SystemsPage";

function App() {
    useEffect(() => {
        AOS.init({
            offset: 50,
            duration: 500,
            easing: "linear",
            delay: 0,
            once: true,
        });
    }, []);
    return (
        <>
            <Layout>
                <Routes>
                    <Route path="/" element={<MainPage />} />
                    <Route path="context" element={<ContextPage />} />
                    <Route path="seo" element={<SeoPage />} />
                    <Route path="smm" element={<SmmPage />} />
                    <Route path="branding" element={<MarketingPage />} />
                    <Route path="apps" element={<ApplicationsPage />} />
                    <Route path="parser" element={<ParserPage />} />
                    <Route path="systems" element={<SystemsPage />} />
                    <Route path="policy" element={<PolicyPage />} />
                </Routes>
            </Layout>
        </>
    );
}

export default App;
