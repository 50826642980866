import React from 'react';

const TgIcon = () => {
    return (
        <svg width="21" height="18" viewBox="0 -2 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.6051 1.57763L17.4868 16.2837C17.2515 17.3216 16.638 17.5799 15.7661 17.091L11.0148 13.5897L8.72228 15.7948C8.46865 16.0484 8.25639 16.2607 7.76742 16.2607L8.10873 11.4216L16.9148 3.4643C17.2977 3.12298 16.8318 2.93382 16.3197 3.27518L5.43328 10.13L0.746532 8.66311C-0.272925 8.34479 -0.291343 7.6436 0.958698 7.15468L19.2905 0.0922834C20.1392 -0.225988 20.8819 0.28136 20.6051 1.57763Z" fill="#0088CC"/>
        </svg>
    );
};

export default TgIcon;