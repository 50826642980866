import React from "react";
import { Element } from "react-scroll";
import { useContentContext } from "../../providers/Content.provider";
import { PricesTag } from "./Prices.styles";
import PricesComponent from "./PricesComponent";

const Prices = () => {
    const { getCaseGroup, getTextGroup } = useContentContext();

    const headTextGroup = getTextGroup("caseshead");
    const landingTextGroup = getTextGroup("caseslanding");
    const shopTextGroup = getTextGroup("casesshop");
    const multiTextGroup = getTextGroup("casesmulti");

    const landingCases = getCaseGroup("landing");
    const landingCasesSorted = landingCases
        ? landingCases.map((landingCase) => ({
              id: landingCase.id,
              title: landingCase.value,
              img: landingCase.ImageSmall,
              ImageBig: landingCase.ImageBig,
          }))
        : [];
    const shopCases = getCaseGroup("shop");
    const shopCasesSorted = shopCases
        ? shopCases.map((shopCase) => ({
              id: shopCase.id,
              title: shopCase.value,
              img: shopCase.ImageSmall,
              ImageBig: shopCase.ImageBig,
          }))
        : [];
    const multiCases = getCaseGroup("multi");
    const multiCasesSorted = multiCases
        ? multiCases.map((multiCase) => ({
              id: multiCase.id,
              title: multiCase.value,
              img: multiCase.ImageSmall,
              ImageBig: multiCase.ImageBig,
          }))
        : [];

    const getContent = (textGroup) => {
        const optionsLeft = [textGroup["option_1"].value, textGroup["option_2"].value, textGroup["option_3"].value];
        const optionsRight = [textGroup["option_4"].value, textGroup["option_5"].value, textGroup["option_6"].value];

        const content = { left: optionsLeft, right: optionsRight };

        return content;
    };

    return (
        <>
            <PricesTag name="#prices">
                <Element>
                    <div className="wrapperPrices">
                        <div className="container">
                            <h2 className="title" data-aos="fade-down">
                                {headTextGroup.heading.value}
                            </h2>
                            <div className="description" data-aos="fade-down">
                                {headTextGroup.subheading.value}
                            </div>
                        </div>
                        <Element name="#landings">
                            <PricesComponent
                                title={landingTextGroup.heading.value}
                                price={landingTextGroup["price 2"].value}
                                content={getContent(landingTextGroup)}
                                id={1}
                                typeOfCase="landings"
                                sliderData={landingCasesSorted}
                            />
                        </Element>
                        <Element name="shops">
                            <PricesComponent
                                title={shopTextGroup.heading.value}
                                margin="225px"
                                price={shopTextGroup["price 2"].value}
                                content={getContent(shopTextGroup)}
                                id={2}
                                typeOfCase="shops"
                                sliderData={shopCasesSorted}
                            />
                        </Element>
                        <Element name="sites">
                            <PricesComponent
                                title={multiTextGroup.heading_1.value}
                                size={true}
                                margin="225px"
                                price={multiTextGroup["price 2"].value}
                                content={getContent(multiTextGroup)}
                                id={3}
                                typeOfCase="multipage"
                                sliderData={multiCasesSorted}
                            />
                        </Element>
                    </div>
                </Element>
            </PricesTag>
        </>
    );
};

// const landing = {
//     left: ["Подключение статистики", "Анализ конкурентов", "Система управления сайтом"],
//     right: ["Возможность “мультистраничности”", "Индивидуальный дизайн", "Адаптивность под планшеты и смартфоны"],
// };
// const shops = {
//     left: ["Интеграция с вашими базами данных", "Система управления сайтом", "Автоматические продажи"],
//     right: ["Простота доработок", "Никаких шаблонных решений", "Система акций и промокодов"],
// };
// const sites = {
//     left: ["Сложные web-сервисы", "Корпоративная стилистика", "SEO-ориентированность"],
//     right: ["Система управления сайтом", "Простота масштабируемости", "Привычный пользователю формат"],
// };

export default Prices;
