import React from "react";
import { Element } from "react-scroll";
import { AnimateWrapper } from "../../../animations/AnimateWrapper";
import star from "../../../images/star.svg";
import { useContentContext } from "../../../providers/Content.provider";
import Dash from "../../common/Dash/Dash";
import { ReasonsTag } from "./Reasons.styles";

const Reasons = () => {
    const { getTextGroup } = useContentContext();

    const textGroup = getTextGroup("whywe");

    return (
        <ReasonsTag>
            <Element name="main-second">
                <div className="container">
                    <Dash color={"#ffffff"} countBefore={1} countAfter={6} top={"0px"} />
                    <div className="content">
                        <h2 className="title" data-aos="fade-down">
                            {textGroup.heading_1.value} <span className="blue blueSpan">{`{`}</span>
                            <span className="white">{textGroup.heading_2.value}</span>
                            <span className="blue blueSpan">{`}`}</span>
                        </h2>
                        <div className="item">
                            <AnimateWrapper animate="fade-left">
                                <h4 className="subtitle" data-aos="fade-up">
                                    {textGroup.argument_1_title.value}
                                </h4>
                                <div className="text" data-aos="fade-down">
                                    {textGroup.argument_1_text.value}
                                </div>
                            </AnimateWrapper>
                        </div>
                        <div className="item">
                            <h4 className="subtitle" data-aos="fade-up">
                                {textGroup.argument_2_title.value}
                            </h4>
                            <div className="text" data-aos="fade-down">
                                {textGroup.argument_2_text.value}
                            </div>
                        </div>
                        <div className="item">
                            <h4 className="subtitle" data-aos="fade-up">
                                {textGroup.argument_3_title.value}
                            </h4>
                            <div className="text" data-aos="fade-down">
                                {textGroup.argument_3_text.value}
                            </div>
                        </div>
                        <div className="item">
                            <h4 className="subtitle" data-aos="fade-up">
                                {textGroup.argument_4_title.value}
                            </h4>
                            <div className="text" data-aos="fade-down">
                                {textGroup.argument_4_text.value}
                            </div>
                        </div>
                    </div>
                    <div className="circle" data-aos="circle">
                        <img src={star} alt="star" className="star" />
                        <div className="first advantage">
                            <span className="blackW">5+</span>
                            <span className="blue">
                                {" "}
                                [<span className="white">..</span>]
                            </span>
                        </div>
                        <div className="second advantage">
                            <span className="blackW">200+</span>
                            <span className="blue"> ></span>
                            <span className="white">></span>
                        </div>
                        <div className="third advantage">
                            <span className="blackW">12+</span>
                            <span className="blue"> //</span>
                            <span className="white">#</span>
                        </div>
                    </div>
                </div>
            </Element>
        </ReasonsTag>
    );
};

export default Reasons;
